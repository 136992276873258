import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
    Container
} from "reactstrap"
import { connect } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import LoadUnlayer from "./loadeditor";
import RsLink from "components/buttons/rsLink";

class EditorLandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName : '',
            design: {},
            id: '',           
            projectId: '',    
            templateId: ''  
        }
        this.handleChange = this.handleChange.bind(this);
        this.exportHtml = this.exportHtml.bind(this);
        this.LoadUnlayerRef = React.createRef();   
    }
    handleChange(event) {
        this.setState({ templateName: event.target.value });
      }
      componentDidMount() {
        const { match } = this.props;
        const { id, projectId, templateId } = match.params;

        this.setState({
            id,
            projectId,
            templateId
        });
        
    }
      exportHtml = () => {
        console.log('this.LoadUnlayerRef:', this.LoadUnlayerRef);
        console.log('this.LoadUnlayerRef.current:', this.LoadUnlayerRef.current);
        if (this.LoadUnlayerRef && this.LoadUnlayerRef.current) {
         console.log('hi');
          this.LoadUnlayerRef.current.exportHtml((htmlContent) => {
            
            console.log('HTML content:', htmlContent.design);
           const d = {
                'agentId' : parseInt(localStorage.getItem("userId")),
                'landingPageProjectId' : parseInt(this.state.id),
                'landingPageTemplateId': parseInt(this.state.templateId),
                'landingPageContent' : JSON.stringify(htmlContent.design),
                'landingPageFullContent' : htmlContent.html,
                'isActive' : true
              }
              const obj = JSON.parse(localStorage.getItem("authUser"));
              const token = obj.resultData.bearerToken;
              const str = 'Bearer ' + token;
              const headers = {
                  'Content-Type': 'application/json',
                  'Authorization': str
              }
              
              toast.loading("Please wait...");
              axios.post('https://rscoreapi.azurewebsites.net/api/ProjectLandingPage', d, {
                  headers: headers
                })
                .then((response) => {
                    toast.dismiss();
                    //toast.success("Template Added");
                    this.props.history.push('/pre-construction/publish-page/' + response.data.projectLandingPage.id + '/' + this.state.projectId + '/' + this.state.templateId);
                })
                .catch((error) => {
                 
                }) 
            });
           

        }
      };
    
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Landing Page Editor | Realty Space</title>
                    </MetaTags>
                    <ToastContainer autoClose={2000} />
                    <Container fluid>
                        {/* Render Breadcrumb */}
                    <div>
                    <span className="float-start mb20">
                        <RsLink className="btn btn-light btn-md" iconClass="bx bx-arrow-back" to={`/pre-construction/campaign/landing-page/` + this.props.match.params.id  + "/"+ this.props.match.params.projectId}>
                            Back
                        </RsLink>
                    </span>
                    <span className="float-end mb20">
                  
                    <RsLink onClick={this.exportHtml} iconClass="bx bx-save">
                        Save & Continue
                    </RsLink>
                        </span>
                    <LoadUnlayer  ref={this.LoadUnlayerRef} />
                    </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }



}
EditorLandingPage.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    match:PropTypes.object

}
export default connect()(withTranslation()(EditorLandingPage))