import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import setup from "../../assets/img/setup.png"
import sales from "../../assets/img/sales.png"
import email from "../../assets/img/email.png"
import market from "../../assets/img/market.png"
import webpage from "../../assets/img/webpage.png"
import deleteImg from "../../assets/img/delete.png"
import dashboardImg from "../../assets/img/data-management.png"

//i18n
import { withTranslation } from "react-i18next"

class ProjectSidebar extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  isDashboardPath = () => {
    const { location } = this.props;
    const dashboardPaths = [
      '/pre-construction/campaign/'
    ];
   
    return dashboardPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.projectId)
    );
  }
  isSetupPath = () => {
    const { location } = this.props;
    const setupPaths = [
      '/pre-construction/create-project/description/',
      '/pre-construction/create-project/feature/',
      '/pre-construction/create-project/media/',
      '/pre-construction/create-project/link/',
      '/pre-construction/create-project/gallery/',
      'pre-construction/create-project/contact/'

    ];
   
    return setupPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.projectId)
    );
  }
 
  isWebpagePath = () => {
    const { location } = this.props;
    const webpagePaths = [
      '/pre-construction/campaign/landing-page/',
      '/pre-construction/campaign/worksheet/',
      '/pre-construction/campaign/open-house-page/',
      '/pre-construction/campaign/download-price-page/',
      '/pre-construction/campaign/rsvp/'
    ];
   
    return webpagePaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.projectId)
    );
  }

  isSalesPath = () => {
    const { location } = this.props;
    const salesPaths = [
      '/pre-construction/campaign/current-prospects/',
      '/pre-construction/campaign/srr-filled/',
      '/pre-construction/campaign/client/'
    ];
   
    return salesPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.projectId)
    );
  }

  isMarketingPath = () => {
    const { location } = this.props;
    const marketingPaths = [
      '/pre-construction/campaign/marketing/'
    ];
   
    return marketingPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.projectId)
    );
  }
  isEmailCampaignsPath = () => {
    const { location } = this.props;
    const CampaignsPaths = [
      '/pre-construction/campaign/send-email/'
    ];
   
    return CampaignsPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.projectId)
    );
  }
  render() {
    const userModule = localStorage.getItem('userModule');
    const isDashboardActive = this.isDashboardPath();
    const isSetupActive = this.isSetupPath();
    const isWebpageActive = this.isWebpagePath();
    const isSalesActive = this.isSalesPath();
    const isMarketingActive = this.isMarketingPath();
    const isEmailCampaignsActive = this.isEmailCampaignsPath();
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar" ref={this.refDiv}>
          <div id="sidebar-menu" className="setting-menu">
           
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to={"/pre-construction/my-project" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
          
                  {/* <span><b>{this.props.t("Management")}</b></span> */}
                </Link>
              </li>
            <li>
                {/* <Link to={"/pre-construction/campaign/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}  className=" "> */}
                  {/* <i className="bx bx-user-plus" /> */}
          
                  {/* <h2 className="ps-4"><b>{this.props.t("Management")}</b></h2> */}
                {/* </Link> */}
              </li>
              <li>
                <Link to={"/pre-construction/campaign/"  + this.props.match.params.id + "/" + this.props.match.params.projectId} className={isDashboardActive ? "project-link active" : "project-link"}>
                <img src={dashboardImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
          
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>
            
              <li>
                <Link to={"/pre-construction/create-project/description/" + this.props.match.params.id + "/" + this.props.match.params.projectId} className={isSetupActive ? "project-link active" : "project-link"}>
                  <img src={setup} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
          
                  <span>{this.props.t("Project Setup")}</span>
                </Link>
              
                
              </li>
              <li className="mm-data">
                <Link to={"/pre-construction/campaign/landing-page/" + this.props.match.params.id + "/" + this.props.match.params.projectId}  className={isWebpageActive ? "project-link active" : "project-link"}>
                
                  <img src={webpage} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Webpages")}</span>
                </Link>
              
              </li>  
              <li>
                <Link to={"/pre-construction/campaign/send-email/"  + this.props.match.params.id + "/" + this.props.match.params.projectId} className={isEmailCampaignsActive ? "project-link active" : "project-link"}>
                  
                <img src={email} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Email Campaigns")}</span>
                </Link>
              </li>
              <li>
                <Link to={"/pre-construction/campaign/current-prospects/"  + this.props.match.params.id + "/" + this.props.match.params.projectId} className={isSalesActive ? "project-link active" : "project-link"}>
                
                <img src={sales} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Sales Activity")}</span>
                </Link>
              </li>
              <li>
                <Link to={"/pre-construction/campaign/marketing/"  + this.props.match.params.id + "/" + this.props.match.params.projectId} className={isMarketingActive ? "project-link active" : "project-link"}>
              
                <img src={market} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Marketing")}</span>
                </Link>
              </li>
             
             
             
            
              <li><Link  to='/pre-construction/my-project' className=" project-link">
                 {/* <i className="bx bx-user-plus" /> */}
                 <img src={deleteImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                 <span>{this.props.t("Delete Project")}</span></Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

ProjectSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  match:PropTypes.object,
}

export default withRouter(withTranslation()(ProjectSidebar))
