import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import { isEmpty, map } from "lodash"
import {
  Button, Card, Col, Container, Input, Label, Row, TabContent, TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink
} from "reactstrap"
import classnames from "classnames"
// Import Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import axios from "axios"
import { API_URL } from "helpers/app_url"
import RsLink from "components/buttons/rsLink";
import Dropzone from "react-dropzone"
class EmailSideBar extends Component {
  constructor(props) {

    super(props)
    this.state = {
      modal: false,
      activeTab: "1",
      agentData:''
    }

    this.togglemodal.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  // componentDidMount() {
  //   const obj = JSON.parse(localStorage.getItem("authUser"));
  //   const token = obj?.resultData?.bearerToken;
  //   const userId = localStorage.getItem("userId");
    
  //   this.setState({ isLoading: true });
  //   axios.get(`${API_URL}Agent/${userId}`, {
  //     headers: { Authorization: `Bearer ${token}` }
  //   })
  //   .then(res => {
  //     const agentData = res.data;
  //     this.setState({ agentData }, () => {
  //       const agentId = this.state.agentData.agentId;
  //       axios.get(`${API_URL}Gmail/SyncGmails/${agentId}`, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       })
  //       .then(response => {
  //         if (response.data === 'Success') {
       
  //           this.fetchInboxMails(agentId, this.state.currentPage);
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error syncing gmails:', error);
  //         this.setState({ isLoading: false });
  //       });
  //     });
  //   })
  //   .catch(error => {
  //     console.error('Error fetching agent data:', error);
  //     this.setState({ isLoading: false });
  //   });
  // }
  toggleTab(tab) {

    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  handleSubmit(value) {
    this.setState({ isLoading: true });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const payload = {
      //name: value.name,
      //from:value.from,
      to: value.to,
      cc: value.cc,
      bcc: value.bcc,
      subject:value.subject,
      body:value.body,
      agentId:value.agentId
    }
    axios.post(`${API_URL}Gmail`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log("API Response:", response.data);
      this.setState({ isLoading: false });
      this.togglemodal();
    })
    .catch(error => {
      console.error("API Error:", error);
      this.setState({ isLoading: false });
    });
  }
  render() {
    const { agentData} = this.props;
    console.log(agentData);
    return (
      <React.Fragment>
                

                  <RsLink className="btn btn-light" iconClass="bx bx-envelope" onClick={this.togglemodal}>Compose</RsLink>

                <Modal
                  isOpen={this.state.modal}
                  role="dialog"
                  autoFocus={true}
                  centered={true}
                  className="exampleModal"
                  tabIndex="-1"
                  toggle={this.togglemodal}
                >
                  <div className="modal-content">
                    <ModalHeader toggle={this.togglemodal}>New Message</ModalHeader>
                    <ModalBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        //name:'Sudhir',
                        //from:'sudhir.singh76@gmail.com',
                        to: '',
                        cc:'',
                        bcc:'',
                        subject: '',
                        agentId: agentData,
                        files: []
                      }}
                      validationSchema={Yup.object().shape({
                        to: Yup.string().email('Invalid email format').required('Email is required'),
                        cc: Yup.string().email('Invalid email format'),
                        bcc: Yup.string().email('Invalid email format'),
                        subject: Yup.string().required('Subject is required'),
                       
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        const obj = JSON.parse(localStorage.getItem("authUser"));
                        const token = obj?.resultData?.bearerToken;
                        const formData = new FormData();
                        formData.append('to', values.to);
                        formData.append('cc', values.cc || '');
                        formData.append('bcc', values.bcc || '');
                        formData.append('subject', values.subject);
                        formData.append('body', values.body);
                        formData.append('agentId', values.agentId);
                        values.files.forEach((file) => {
                          formData.append('files', file, file.name);
                        });
                        this.setState({ isLoading: true });
                        axios
                          .post(`${API_URL}Gmail`, formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          .then((response) => {
                            console.log('Upload successful:', response.data);
                            this.setState({ isLoading: false });
                            this.togglemodal();
                          })
                          .catch((error) => {
                            console.error('Upload error:', error);
                            this.setState({ isLoading: false });
                          })
                          .finally(() => {
                            setSubmitting(false);
                          });
                      }}
                  
                  >
                      {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                  <div className="mb-3">
                    <Field
                      name="to"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="To"
                    />
                    <ErrorMessage name="to" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="cc"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="CC"
                    />
                    <ErrorMessage name="cc" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="bcc"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="BCC"
                    />
                    <ErrorMessage name="bcc" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="subject"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Subject"
                    />
                    <ErrorMessage name="subject" component="div" className="text-danger" />
                  </div>
                    
                  <div className="mb-3">
                    <label>Message</label>
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('body', data);
                      }}

                    />
                    <ErrorMessage name="body" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                  <label>Attached Files</label>
                  <Dropzone
                      onDrop={(acceptedFiles) => {
                        setFieldValue('files', [...(values.files || []), ...acceptedFiles]);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div className="dz-message needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className="mt-2">
                  {Array.isArray(values.files) &&
                    values.files.map((file, index) => (
                      <li key={index} className="d-flex align-items-center">
                        <span className="me-2">{file.name}</span>
                        <span className="float-end"> 
                          <button
                          type="button"
                          className="btn btn-sm"
                          onClick={() => {
                            // Remove file from the list
                            const updatedFiles = values.files.filter((_, i) => i !== index);
                            setFieldValue('files', updatedFiles);
                          }}
                        >
                          <i className="fas fa-window-close "></i>
                        </button></span>
                       
                      </li>
                    ))}
                </ul>
                  </div>    
                  <ModalFooter>
                    <Button type="button" color="secondary" onClick={this.togglemodal}>
                      Close
                    </Button>
                    <Button type="submit" color="primary">
                      Send <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
                    </ModalBody>
                    
                  </div>
                </Modal>
                <Nav vertical className="emailTab">
                <NavItem>
                  <Link 
                    className={`nav-link`} 
                    to="/email-inbox"
                  >
                    <span className="d-none d-sm-block"><i className="mdi mdi-email-outline me-2"></i> Inbox</span>
                  </Link>
                  </NavItem> 
                  <NavItem>
                  <Link 
                    className={`nav-link`} 
                    to="/email-sent"
                  >
                    <span className="d-none d-sm-block"><i className="mdi mdi-email-check-outline me-2"></i> Sent Mail</span>
                  </Link>
                  </NavItem> 
                     
                  </Nav>
      </React.Fragment>
    )
  }
}
EmailSideBar.propTypes = {
  activeTab: PropTypes.any,
  agentData:PropTypes.object
}
export default EmailSideBar