import PropTypes from "prop-types";
import React, { useState } from "react";

const CopyText = ({textToCopy}) => {
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000); // Clear message after 2 seconds
      })
      .catch(() => setCopySuccess("Failed to copy!"));
  };

 

  return (
    <>
      <small
        className="font-size-18"
        style={{ cursor: "pointer" }}
        onClick={() => handleCopy(textToCopy)}
        title="Copy to clipboard"
      >
        <i className="bx bx-copy-alt"></i>
      </small>
      {copySuccess && <span style={{ marginLeft: "10px", color: "green" }}>{copySuccess}</span>}
      </>
  );
};
CopyText.propTypes = {
    textToCopy: PropTypes.object
  };
export default CopyText;
