import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody } from "reactstrap"
import maintenance from "../assets/images/maintenance.svg"
class NoAccess extends Component {
  render() {
    return (
      <React.Fragment>
         <Row>
              <Col xs="12" className="text-center">
                <div className="home-wrapper">
                 

                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="maintenance-img">
                        <img
                          src={maintenance}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                    </Col>
                  </Row>
                  <h3 className="mt-5">Unauthorized access</h3>
                  

                
                </div>
              </Col>
            </Row>
      </React.Fragment>
    )
  }
}

NoAccess.propTypes = {
  children: PropTypes.array
}

export default NoAccess
