import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import axios from "axios";
import { classnames } from "helpers/helper";
import { Calendar  } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent
} from "reactstrap"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import { API_URL } from "helpers/app_url";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import SaveButton from "components/buttons/save";
import RsLink from "components/buttons/rsLink";
import UpdateButton from "components/buttons/updateBtn";
import { getClientDetail} from "../../store/clients/actions"
class OpenList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      ccTab:"1"
    }
    this.toggleEditCanvas = this.toggleEditCanvas.bind(this);
    this.toggleSend = this.toggleSend.bind(this);
  }
  toggleSend(){
    this.setState({ isSend: !this.state.isSend });
    
  }
  cctoggle(tab) {
    if (this.state.ccTab !== tab) {
      this.setState({
        ccTab: tab,
      });
    }
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail } = this.props;
    onGetClientDetail(params.clientId);
    this.fetchData();
  }
  fetchData = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const response = await axios.get(`${API_URL}ClientSellerOpenhouse/byid`, {
        headers: {
          'Authorization': str,
          'Content-Type': 'application/json',
        },
        params: {
          openhouseId: this.props.match.params.id
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleEditCanvas() {
    this.setState({ isRight: !this.state.isRight });
    //this.toggle();
  }
  render() {
    const {clietDetail } = this.props;
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar clientDetail={clietDetail}   />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                {/* <Col sm="7"> */}
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Open House")}
            />
            {/* </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col> */}
            </Row>
             <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Visitors

                      <span className="float-end"><RsLink iconClass="mdi mdi-update" onClick={this.toggleSend}>Visitor Update</RsLink></span>

                    </h5>
					<div className="">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
                                <th><input type="checkbox" className="selection-input-4" /></th>
								<th>Name</th>
								<th>Date</th>
								<th>Questions</th>
								<th>Contact</th>
                <th>Action</th>
                
							  </tr>
							</thead>
							<tbody>
              <tr>
                  <td>
                  <input type="checkbox" className="selection-input-4" />
                  </td>
                  <td>John Deo</td>
                  <td>14 Nov 2024</td>
                  <td>
                Are you Pre-Approved? - No<br/>
                How soon looking to move? - 3 Months<br/>
                Currently working with an agent? - No </td>
                <td>
                Email : john@email.ca<br/>
                Cell : 4168956790 </td>
                <td>
                <UncontrolledDropdown direction="up">
                  <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                      Action <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem  href="#" onClick={this.toggleEditCanvas}>View Details</DropdownItem>
                      <DropdownItem  href="#">Convert to Lead</DropdownItem>
                      <DropdownItem  href="#">Delete</DropdownItem>


                  </DropdownMenu>
              </UncontrolledDropdown>
                </td>
                </tr>
                <tr>
                  <td>
                  <input type="checkbox" className="selection-input-4" />
                  </td>
                  <td>Mohit</td>
                  <td>13 Oct 2024</td>
                  <td>
                Are you Pre-Approved? - No<br/>
                How soon looking to move? - 3 Months<br/>
                Currently working with an agent? - No </td>
                <td>
                Email : mohit@esimplified.ca<br/>
                Cell : 4168956790 </td>
                <td>
                <UncontrolledDropdown direction="up">
                  <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                      Action <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem  href="#" onClick={this.toggleEditCanvas}>View Details</DropdownItem>
                      <DropdownItem  href="#">Convert to Lead</DropdownItem>
                      <DropdownItem  href="#">Delete</DropdownItem>


                  </DropdownMenu>
              </UncontrolledDropdown>
                </td>
                </tr>
             
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
            <Offcanvas
                      isOpen={this.state.isSend}
                      direction="end"
                      toggle={this.toggleSend}
                    >
                      <OffcanvasHeader toggle={this.toggleSend}>
                        Send Document 
                      </OffcanvasHeader>
                      <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   

                  }}
                  validationSchema={Yup.object().shape({
                    documentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                        <Col sm="12"><h5>Visitor : 1</h5></Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="documentTitle">
                              {this.props.t("Select Email to send")}
                            </Label>
                            <Field
                              as="select"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.emailSubject && touched.emailSubject
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="emailSubject"
                            >
                              <option value="">Select</option>
                              <option value="Instructions for Offers">Instructions for Offers</option>
                              <option value="Upcoming Open house">Upcoming Open house</option>
                              <option value="Property available for Offer">Property available for Offer</option>
                              <option value="Property now sold">Property now sold</option>
                              </Field>
                             <ErrorMessage
                              name="emailSubject"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup>
                              {/* <h6 style={{ marginTop: 30 }}>

                                <strong>{this.props.t("Content")}</strong>
                              </h6> */}
                              <Label htmlFor="projectAdditionalFeatureDescription">Email Content</Label>
                              <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('emailContent', data);
                                }}

                              />
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                        
                        <UpdateButton iconClass="bx bx-send">{this.props.t("Send")}</UpdateButton>{ " " }
                        
                        </Col>
                    </Row>

                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>

                    <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleEditCanvas}
              style={{width:900}}
            >
              <OffcanvasHeader toggle={this.toggleEditCanvas}>
                View Detail
              </OffcanvasHeader>
              <OffcanvasBody>
              <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10 text-center"
                                  >
                                    

                                    <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "1",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("1");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Detail")}
                                        </span>
                                      </NavLink>
                                    
                                    </NavItem>
                                    <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "2",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("2");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Add Notes")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "3",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("3");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Next Follow up")}
                                        </span>
                                      </NavLink>
                                    </NavItem>      
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "4",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("4");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Set Appointment")}
                                        </span>
                                      </NavLink>
                                    </NavItem>      

                                  
                    </Nav>
                    <TabContent
                      activeTab={this.state.ccTab}
                      className="p-3 text-muted"
                    >
                       <TabPane tabId="1">
                       <Formik
                                                        enableReinitialize={true}
                                                        initialValues={{
                                                            //clientListingId: (this.state && this.state.clientListingId) || "",

                                                            firstName: (this.state && this.state.firstName) || "",
                                                            email: (this.state && this.state.email) || "",
                                                            phone: (this.state && this.state.phone) || "",
                                                            clientOpenhouseLeadSourceId: this.props.match.params.clientOpenhouseLeadSourceId
                                                           

                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            //clientListingId: Yup.string().required("This is Required"),
                                                            firstName: Yup.string().required("This is Required"),
                                                            phone: Yup.string().matches(
                                                                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                                                "Enter valid Contact number"
                                                            ).required(
                                                                "Please Enter Contact Number"
                                                            ),
                                                            email: Yup.string().email("Must be a valid Email")
                                                                .max(255)
                                                                .required("Email is required")
                                                        })}

                                                        onSubmit={this.handleSellerOphouseLead}
                                                    >
                                                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                                                            <Form
                                                                className="needs-validation"
                                                            >

                                                                <Row>
                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="firstName">
                                                                                {this.props.t("Your Name")}
                                                                            </Label>
                                                                            <Field
                                                                                name="firstName"
                                                                                onChange={handleChange}
                                                                                type="text"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.firstName && touched.firstName
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                                id="firstName"
                                                                            />
                                                                            <ErrorMessage
                                                                                name="firstName"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="yourEmail">
                                                                                {this.props.t("Your Email")}
                                                                            </Label>
                                                                            <Field
                                                                                name="email"
                                                                                onChange={handleChange}
                                                                                type="text"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.email && touched.email
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                                id="email"
                                                                            />
                                                                            <ErrorMessage
                                                                                name="email"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="yourContact">
                                                                                {this.props.t("Your Phone")}
                                                                            </Label>
                                                                            <Field
                                                                                name="phone"
                                                                                onChange={handleChange}
                                                                                type="text"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.phone && touched.phone
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                                id="phone"
                                                                            />
                                                                            <ErrorMessage
                                                                                name="phone"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbPreApproved">
                                                                                {this.props.t("Are you pre-approved?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbPreApproved" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>

                                                                            </Field>
                                                                          
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbHowSoonLookingFor">
                                                                                {this.props.t("How soon are you looking to move?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbHowSoonLookingFor" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                                <option value="ASAP">As Soon As Possible</option>
                                                                                <option value="1 Month">In 1 Month</option>
                                                                                <option value="3 Months">In 3 Months</option>
                                                                                <option value="6 Months">In 6 Months</option>
                                                                                <option value="After 6 Months">After 6 Months</option>

                                                                            </Field>
                                                                            
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbWorkingWithAgent">
                                                                                {this.props.t("Are you currently working with an agent?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbWorkingWithAgent" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                               
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>

                                                                            </Field>
                                                                            
                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>

                                                                <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>


                                                            </Form>
                                                        )}
                                                    </Formik>
                      </TabPane>
                      <TabPane tabId="2">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          addNote: (this.state && this.state.addNote) || "",
                          logDate: new Date(),
                         
                        
                        }}
                        validationSchema={Yup.object().shape({
                            addNote: Yup.string().max(500, 'This filed contains only 500 characters only').required("This is Required")
                        })}

                        onSubmit={this.handleLeadNoteSubmit}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                          
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <textarea
                                          name="addNote"
                                          onChange={handleChange}
                                          // placeholder="only 500 Characters are allowed"
                                          type="textarea"
                                          className={
                                            "form-control" +
                                            (errors.addNote && touched.addNote
                                              ? " is-invalid"
                                              : "")
                                          }
                                          id="addNote"
                                        />
                                         <ErrorMessage
                                              name="addNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton>Save</SaveButton></div>


                                  </Form>
                        )}
                      </Formik>
                      </TabPane>
                      <TabPane tabId="3">
                      <Formik
                    enableReinitialize={true}
                    initialValues={{
                        addNote: (this.state && this.state.addNote) || "",
                      


                    }}
                    validationSchema={Yup.object().shape({
                        addNote: Yup.string().max(500,'Only 500 Characters are allowed').required("This is Required")
                    })}

                    onSubmit={this.handleLeadFollowSubmit}
                >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                        <Form
                            className="needs-validation"
                        >

                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        
                                        <textarea
                                            name="addNote"
                                            onChange={handleChange}
                                            type="textarea"
                                            className={
                                                "form-control" +
                                                (errors.addNote && touched.addNote
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            id="addNote"
                                        />
                                        <ErrorMessage
                                            name="addNote"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup className="mb15">

                                        <Label htmlFor="logDate">
                                            {this.props.t("Date")}
                                        </Label>
                                        <div className="clearfix"></div>
                                        <Calendar  onChange={(value) => setFieldValue('appADate', value[0])} showIcon />
                                        <ErrorMessage
                                            name="logDate"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>

                                </Col>
                            </Row>

                            <div className="clearfix"><SaveButton>Save</SaveButton></div>


                        </Form>
                    )}
                </Formik>                               
                      </TabPane>
                      <TabPane tabId="4">
                      <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    appAjenda: (this.state && this.state.appAjenda) || "",
                                    apptype: '',
                                    appPriority: '',
                                    appointmentLocationType: '',
                                    appADate: new Date(),
                                    appStat: new Date(),
                                    appEnd: new Date(),
                                    contactName: '',
                                    contactPhone: '',
                                    contactEmail: '',
                                    refId: '',
                                    appointmentLocation:'',
                                    leadId:parseInt(this.props.match.params.id)
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appAjenda: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    apptype : Yup.number().required("This field is required"),
                                    appPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                    // appointmentLocation :  Yup.number().required("This field is required")
                                    
                                  })}

                                  onSubmit={this.handelValidAppLead}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                      className="needs-validation"
                                    >
                                      <Row>
                                        <Col md="3">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="appAjenda">
                                              {this.props.t("Agenda*")}
                                            </Label>
                                            <input
                                              name="appAjenda"
                                              type="text"
                                              value={values.appAjenda}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.appAjenda && touched.appAjenda
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="appAjenda"
                                            />
                                            <ErrorMessage
                                              name="appAjenda"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appADate">
                                              {this.props.t("Date")}
                                            </Label>
                                            
                                            <Calendar value={values.appADate}  onChange={(value) => setFieldValue('appADate', value[0])} showIcon />
                                          
                                            <ErrorMessage
                                              name="appADate"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appStat">
                                              {this.props.t("Start Time")}
                                            </Label>
                                            <Calendar onChange={(value) => setFieldValue('appStat', value[0])} timeOnly showIcon />
                                           

                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appEnd">
                                              {this.props.t("End Time")}
                                            </Label>
                                           
                                            <Calendar onChange={(value) => setFieldValue('appEnd', value[0])} timeOnly showIcon />
                                          
                                          
                                           
                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="apptype">
                                              {this.props.t("Appointment Type*")}
                                            </Label>
                                            <Field as="select" name="apptype" className={
                                                "form-control" +
                                                (errors.apptype && touched.apptype
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                            
                                            </Field>
                                            <ErrorMessage
                                              name="apptype"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appPriority">
                                              {this.props.t("Appointment Priority*")}
                                            </Label>
                                            <Field as="select" name="appPriority" className={
                                                "form-control" +
                                                (errors.appPriority && touched.appPriority
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                             
                                            </Field>
                                            <ErrorMessage
                                              name="appPriority"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentLocationType">
                                              {this.props.t("Location Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentLocationType" className={
                                                "form-control" +
                                                (errors.appointmentLocationType && touched.appointmentLocationType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value=''>Select</option>
                                             
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentLocationType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">
                                          <Label htmlFor="appointmentLocation">
                                              {this.props.t("Location")}
                                            </Label>
                                          <Field
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />



                                          </FormGroup>
                                        </Col>
                                      </Row>


                                  

                                      <div className="clearfix"><SaveButton>Save</SaveButton></div>
                                    </Form>
                                  )}
                                </Formik>
                      </TabPane>
                    </TabContent>  
            </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
OpenList.propTypes = {
  t: PropTypes.any,
  match:PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)) 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OpenList))
