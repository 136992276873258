import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import prefrence from "../../assets/images/rsicons/prefrence.png"

//import Lead from "./Lead";
import ClientLink from "./client-link";
import { getClientDetail, addClientImportantDate, addClientChild, getClientImportantDate, getClientChild, deleteClientImportant, updateClientProfile} from "../../store/clients/actions"
import Switch from "react-switch"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import SaveButton from "components/buttons/save";
import ClientProfileBreadcrumbs from "components/Common/ClientProfileBreadcrumb";
import ClientProfileMenu from "./menu/client-profile-menu";
class ClientSecProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ],
      col9: false,
      col8: false,
      col15: false,
      col10: false,
      col11: false,
      col12: false,
    }
    this.handleImportantDateSubmit = this.handleImportantDateSubmit.bind(this)
    this.handleChildSubmit = this.handleChildSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (importantdate) => {
    this.setState({ importantdate: importantdate });
    this.setState({ deleteModal: true });
  };
  handleDeleteImportantDate = () => {
    const { onDeleteImportantDate } = this.props;
    const { importantdate } = this.state;
    
    if (importantdate.id !== undefined) {
      onDeleteImportantDate(importantdate);
      this.setState({ deleteModal: false });
    }
  };
  handleImportantDateSubmit(value){
    this.props.addClientImportantDate(value, this.props.history);
  }

  handleChildSubmit(value){
    this.props.addClientChild(value, this.props.history);
  }
  t_col8() {
    this.setState({
      col8: !this.state.col8,
      col9: false,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col9() {
    this.setState({
      col8: false,
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col8: false,
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col8: false,
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col8: false,
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col8: false,
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }

componentDidMount() {
  const { match: { params }, onGetClientDetail, onGetClientImportantDate, onGetClientChild } = this.props;
  onGetClientDetail(params.clientId);
  onGetClientImportantDate(params.id);
  onGetClientChild(params.id)
 
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;

  const str = 'Bearer ' + token;
  axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
    .then(res => {
      const clientTypes = res.data;
      this.setState({ clientTypes });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const sources = res.data;
          this.setState({ sources });
        })
    
}
  render() {
    const { clietDetail, importantdates, childs, loading, onUpdateClientProfile } = this.props;
    const importantdate = this.state.importantdate;
    const {  deleteModal } = this.state;
    //console.log(loading);
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Inactive
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Active
        </div>
      )
    }

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteImportantDate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />

            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Col lg="8" className="ps-0">
             <ClientProfileBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(clietDetail.firstName +" " + clietDetail.lastName + " Profile")}
            />
            </Col>
            <Col lg="3">
           
              </Col>
            </Row>
            <ClientProfileMenu />
            <Row>
              
              
              <Col xl="12">
            
              <Card>
                          <CardBody>
                          <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: clietDetail.id,
                          clientId: clietDetail.clientId,
                          agentId:clietDetail.agentId,
                          userLogin:clietDetail.userLogin,
                          title:      clietDetail.title,
                          firstName: clietDetail.firstName,
                          lastName: clietDetail.lastName,
                          clientPropertyTypeId:clietDetail.clientPropertyTypeId,
                          clientTypeId: clietDetail.clientTypeId,
                          clientSourceId: clietDetail.clientSourceId,
                          primaryContactId:clietDetail.primaryContactId,
                          secondaryContactId:clietDetail.secondaryContactId,
                          addressId:clietDetail.addressId,
                          isActive:clietDetail.isActive,
                          referedBy:clietDetail.referedBy,
                          howOffenClient:clietDetail.howOffenClient,
                          notes:clietDetail.notes,

                          fullAddress:clietDetail?.clientAddress?.fullAddress,
                          addressLine1:clietDetail?.clientAddress?.addressLine1,
                          addressLine2:clietDetail?.clientAddress?.addressLine2,
                          nearestIntersection:clietDetail?.clientAddress?.nearestIntersection,
                          city:clietDetail?.clientAddress?.nearestIntersection,
                          province:clietDetail?.clientAddress?.province,
                          postal:clietDetail?.clientAddress?.postal,
                          country:clietDetail?.clientAddress?.country,
                          longitude:clietDetail?.clientAddress?.longitude,
                          latitude:clietDetail?.clientAddress?.latitude,

                          primaryTitle:clietDetail?.primaryContact?.title ?? '',
                          contactFName: clietDetail?.primaryContact?.contactFName,
                          contactLName: clietDetail?.primaryContact?.contactLName,
                          organization:clietDetail?.primaryContact?.organization,
                          jobTitle:clietDetail?.primaryContact?.jobTitle,
                          industry:clietDetail?.primaryContact?.industry,


                          mobile: clietDetail?.primaryContact?.cContact.mobile,
                          email: clietDetail?.primaryContact?.cContact.email,
                          phone: clietDetail?.primaryContact?.cContact.phone,
                          office: clietDetail?.primaryContact?.cContact.office,
                          phoneExt: clietDetail?.primaryContact?.cContact.phoneExt ??'',

                          secondaryTitle:clietDetail?.secondaryContact?.title ?? '',
                          secondaryContactFName:clietDetail?.secondaryContact?.contactFName,
                          secondaryContactLName:clietDetail?.secondaryContact?.contactLName,
                          secondaryOrganization:clietDetail?.secondaryContact?.organization,
                          secondaryJobTitle:clietDetail?.secondaryContact?.jobTitle,
                          secondaryIndustry:clietDetail?.secondaryContact?.industry,

                          secondaryMobile:clietDetail?.secondaryContact?.mobile,
                          secondaryEmail:clietDetail?.secondaryContact?.email,
                          secondaryOffice:clietDetail?.secondaryContact?.office,
                          secondaryPhoneExt:clietDetail?.secondaryContact?.phoneExt ??'',
                        

                        }}
                        validationSchema={Yup.object().shape({
                          clientTypeId: Yup.string().required("This is Required"),
                          title: Yup.string().required("This is Required"),
                          primaryTitle : Yup.string().required("This is Required"),
                          contactFName :Yup.string().max(100,"100 Characters are allowed").required("This is Required"),
                          contactLName : Yup.string().max(100,"100 Characters are allowed"),
                          mobile : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed").required("This is Required"),
                          phone : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          email : Yup.string().max(100,"100 Characters are allowed").email().required("This is Required"),
                          office : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          phoneExt : Yup.string().min(3,"Min. 3 Digts are Required").max(6,"Max. 6 Digts are Required"),
                          secondaryTitle :  Yup.string().required("This is Required"),
                          secondaryContactFName : Yup.string().max(100,"100 Characters are allowed").required("This is Required"),
                          secondaryContactLName : Yup.string().max(100,"100 Characters are allowed"),
                          secondaryMobile : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed").required("This is Required"),
                          secondaryEmail : Yup.string().max(100,"100 Characters are allowed").email().required("This is Required"),
                          secondaryOffice : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          secondaryPhoneExt : Yup.string().min(3,"Min. 3 Digts are Required").max(6,"Max. 6 Digts are Required"),
                          notes : Yup.string().max(2000,"Max. 2000 Characters are allowed").required("This is Required"),

                        
                        })}

                        onSubmit={values => {
                          //console.log(isEdit);
                        
                            const updateClient = {
                          id: values.id,
                          clientId: values.clientId,
                          agentId:values.agentId,
                          //userLogin:values.userLogin,
                          title:      values.title,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          clientPropertyTypeId:values.clientPropertyTypeId,
                          clientTypeId: values.clientTypeId,
                          clientSourceId: values.clientSourceId,
                          primaryContactId:values.primaryContactId,
                          secondaryContactId:values.secondaryContactId,
                          addressId:values.addressId,
                          isActive:values.isActive,
                          referedBy:values.referedBy,
                          howOffenClient:values.howOffenClient,
                          notes:values.notes,

                          fullAddress:values.fullAddress,
                          addressLine1:values.addressLine1,
                          addressLine2:values.addressLine2,
                          nearestIntersection:values.nearestIntersection,
                          city:values.nearestIntersection,
                          province:values.province,
                          postal:values.postal,
                          country:values.country,
                          longitude:values.longitude,
                          latitude:values.latitude,

                          primaryTitle:values.title,
                          contactFName: values.contactFName,
                          contactLName: values.contactLName,
                          organization:values.organization,
                          jobTitle:values.jobTitle,
                          industry:values.industry,


                          mobile: values.mobile,
                          email: values.email,
                          phone: values.phone,
                          office: values.office,
                          phoneExt: values.phoneExt,

                          secondaryTitle:values.secondaryTitle,
                          secondaryContactFName:values.secondaryContactFName,
                          secondaryContactLName:values.secondaryContactLName,
                          secondaryOrganization:values.secondaryOrganization,
                          secondaryJobTitle:values.secondaryJobTitle,
                          secondaryIndustry:values.secondaryIndustry,

                          secondaryMobile:values.secondaryMobile,
                          secondaryEmail:values.secondaryEmail,
                          secondaryOffice:values.secondaryOffice,
                          secondaryPhoneExt:values.secondaryPhoneExt,
                        };
                            onUpdateClientProfile(updateClient);
                        
                          
                        }}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (

                          <Form
                            className="needs-validation"
                          >

{/* <h5 className="card-title">Secondary Contact Details</h5> */}
              
              <Row>
                   <Col sm="1">
                   <FormGroup className="mb-3">
                       <Label htmlFor="secondaryTitle">
                         {this.props.t("Title*")}
                       </Label>
                       <Field as="select" name="secondaryTitle" className={
                           "form-control" +
                           (errors.secondaryTitle && touched.secondaryTitle
                             ? " is-invalid"
                             : "")
                         } onChange={handleChange}>
                       <option value="">Select</option>
                       {
                                 this.state.titles
                                   .map(title =>
                                     <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                   )
                               }
                       </Field>
                       <ErrorMessage
                         name="secondaryTitle"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>

                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="secondaryContactFName">
                         {this.props.t("First Name")}
                       </Label>
                       <Field
                         name="secondaryContactFName"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryContactFName && touched.secondaryContactFName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryContactFName"
                       />
                        <ErrorMessage
                         name="secondaryContactFName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>
                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="secondaryContactLName">
                         {this.props.t("Last Name")}
                       </Label>
                       <Field
                         name="secondaryContactLName"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryContactLName && touched.secondaryContactLName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryContactLName"
                       />
                        <ErrorMessage
                         name="secondaryContactLName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>     
                   <Col sm="2">
                   <FormGroup className="mb-3">
                       <Label htmlFor="secondaryMobile">
                         {this.props.t("Mobile*")}
                       </Label>
                       <Field
                         name="secondaryMobile"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryMobile && touched.secondaryMobile
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryMobile"
                       />
                        <ErrorMessage
                         name="secondaryMobile"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>
             
                   <Col sm="2">
                   <FormGroup className="mb-3">
                       <Label htmlFor="secondaryEmail">
                         {this.props.t("Email*")}
                       </Label>
                       <Field
                         name="secondaryEmail"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryEmail && touched.secondaryEmail
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryEmail"
                       />
                        <ErrorMessage
                         name="secondaryEmail"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

            
                   </Row>
                      
                       <Row>
                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="secondaryOrganization">
                         {this.props.t("Company Name")}
                       </Label>
                       <Field
                         name="secondaryOrganization"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryOrganization && touched.secondaryOrganization
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryOrganization"
                       />
                        <ErrorMessage
                         name="secondaryOrganization"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="secondaryJobTitle">
                         {this.props.t("Job Title")}
                       </Label>
                       <Field
                         name="secondaryJobTitle"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryJobTitle && touched.secondaryJobTitle
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryJobTitle"
                       />
                        <ErrorMessage
                         name="secondaryJobTitle"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="2">
                       <FormGroup className="mb-3">
                       <Label htmlFor="secondaryOffice">
                         {this.props.t("Office Phone")}
                       </Label>
                       <Field
                         name="secondaryOffice"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryOffice && touched.secondaryOffice
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryOffice"
                       />
                        <ErrorMessage
                         name="secondaryOffice"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <Col sm="1">
                       <FormGroup className="mb-3">
                       <Label htmlFor="secondaryPhoneExt">
                         {this.props.t("Ext")}
                       </Label>
                       <Field
                         name="secondaryPhoneExt"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryPhoneExt && touched.secondaryPhoneExt
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryPhoneExt"
                       />
                        <ErrorMessage
                         name="secondaryPhoneExt"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="secondaryIndustry">
                         {this.props.t("Industry")}
                       </Label>
                       <Field
                         name="secondaryIndustry"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.secondaryIndustry && touched.secondaryIndustry
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="secondaryIndustry"
                       />
                        <ErrorMessage
                         name="secondaryIndustry"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <div className="clearfix"><SaveButton>Save</SaveButton></div>

                       </Row>
                            </Form>
                           )}
                           </Formik>
                      
                          </CardBody>
                        </Card>


            
            </Col>
            
            </Row>
            </Col>
            </Row>
      
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSecProfile.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientImportantDate:PropTypes.func,
  addClientChild:PropTypes.func,
  onGetClientImportantDate:PropTypes.func,
  onGetClientChild:PropTypes.func,
  importantdates:PropTypes.array,
  childs:PropTypes.array,
  onDeleteImportantDate:PropTypes.func,
  loading:PropTypes.object,
  onUpdateClientProfile:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    loading: Clients.loading,
    clietDetail: Clients.clietDetail,
    importantdates:Clients.importantdates,
    childs:Clients.childs
    

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientImportantDate:(data) => dispatch(addClientImportantDate(data)),
  addClientChild:(data) => dispatch(addClientChild(data)),
  onGetClientImportantDate: clientId => dispatch(getClientImportantDate(clientId)),
  onGetClientChild: clientId => dispatch(getClientChild(clientId)),
  onDeleteImportantDate : id => dispatch(deleteClientImportant(id)),
  onUpdateClientProfile: (data) => dispatch(updateClientProfile(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSecProfile))
