import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { map } from "lodash"
import Moment from 'react-moment';
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getAllProjectAction, getAllProjectLead, getProjectLeadByWorsheetStatus } from "../../../store/actions"
import ProjectFilterMenu from "./lead-filter-menu";
import noImg from "../../../assets/img/no-image.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class SubmittedWorksheetLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: ''
    }
  }
    componentDidMount() {    
        const { onGetAllProject, onGetAllProjectLead, onGetProjectLeadByWorsheetStatus } = this.props;
        onGetAllProject(localStorage.getItem('userId'),localStorage.getItem('brokerId'));
        //onGetAllProjectLead(localStorage.getItem('userId'));
        onGetProjectLeadByWorsheetStatus(localStorage.getItem('userId'),0,'',true)
    }


  render() {
    const { projects, allleads, worksheetleads} = this.props;

    if (!projects || !worksheetleads) {
        return <div>Loading...</div>;
      }
    const allProjects = projects.map(project => ({
        title: project.projectName,
        logo:project.projectLogoLink,
        leads: worksheetleads.filter(d => d.campaignProjectId === project.id)
      }));
  
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const CustomPrevArrow = ({ className, style, onClick }) => (
      <div
        className={`${className} custom-prev-arrow`}
        style={{
          ...style,
          display: "block",
          background: "#000",
          borderRadius: "50%",
          padding: "10px",
          zIndex: 2,
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left" style={{ color: "#fff" }}></i>
      </div>
    );
    
    // Custom Right Arrow
    const CustomNextArrow = ({ className, style, onClick }) => (
      <div
        className={`${className} custom-next-arrow`}
        style={{
          ...style,
          display: "block",
          background: "#000",
          borderRadius: "50%",
          padding: "10px",
          zIndex: 2,
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right" style={{ color: "#fff" }}></i>
      </div>
    );
    const sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 6, // Number of slides to show
      slidesToScroll: 1,
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="mb20 project-header">
              <Col lg="12">
                <Breadcrumbs
                  title={this.props.t("Project Lead")}
                  breadcrumbItem={this.props.t("Worksheet Pending")}
                />
              </Col>
            </Row>
            <Row>
                <ProjectFilterMenu />
                
            </Row>
            <Row>
                  <Col sm="12">
                  <Card>
                <CardBody>
                  <div className="toHeadingRow">
                    <Slider {...sliderSettings}>
                      {allProjects.map((allProject) => (
                        <div key={allProject.title} className="project-slide">
                          <div style={{paddingLeft:15}}>
                            <img
                              src={allProject.logo || noImg}
                              className=""
                              alt={allProject.title}
                              style={{ height: 50 }}
                            />
                            <h6 style={{marginTop:5}}>{allProject.title}</h6>
                          </div>
                          <div className="mt-3">
                            {allProject.leads.map((lead) => (
                              <div className="container p-0" key={lead.id}>
                                <div className="card custom-card">
                                  <div className="card-body">
                                    <h5 className="card-title mb-2">
                                      <Link
                                        to={`/lead/lead-detail/${lead.id}/${lead.leadId}`}
                                      >
                                        {`${lead.firstName} ${lead.lastName}`}
                                      </Link>
                                    </h5>
                                    <div>Phone: {lead.phone}</div>
                                    <div>Email: {lead.email}</div>
                                    <div>
                                      Submitted - {lead.unitStatusText}
                                    </div>
                                    <div>
                                      Date:{" "}
                                      {lead.lastActivity ? (
                                        <Moment format="D MMM YY">
                                          {lead.lastActivity}
                                        </Moment>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="row pt-4">
                                      <div className="col-6">
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            this.toggleRightCanvas(lead)
                                          }
                                        >
                                          Quick Edit
                                        </Link>
                                      </div>
                                      <div className="col-6">
                                        <Link
                                          to={`/lead/lead-detail/${lead.id}/${lead.leadId}`}
                                        >
                                          View Detail
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </CardBody>
              </Card>
                  </Col>
                </Row>  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

SubmittedWorksheetLead.propTypes = {

  className: PropTypes.any,
  t: PropTypes.any,
  loading:PropTypes.object,
  onGetAllProject:PropTypes.func,
  projects:PropTypes.array,
  onGetAllProjectLead:PropTypes.func,
  allleads:PropTypes.array,
  onGetProjectLeadByWorsheetStatus:PropTypes.func,
  worksheetleads:PropTypes.array
  
}

const mapStateToProps = ({ Project }) => ({
  projects:Project.projects,
  loading: Project.loading,
  allleads: Project.allleads,
  worksheetleads:Project.worksheetleads
})

const mapDispatchToProps = dispatch => ({
    onGetAllProject:(agentId, brokerId) => dispatch(getAllProjectAction(agentId, brokerId)),
    onGetAllProjectLead: (agentId) => dispatch(getAllProjectLead(agentId)),
    onGetProjectLeadByWorsheetStatus:(agentId, projectId, unitStatusText, isWorkSheetFilled) => dispatch(getProjectLeadByWorsheetStatus(agentId, projectId, unitStatusText, isWorkSheetFilled))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SubmittedWorksheetLead)))