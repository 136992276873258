import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import './rsbutton.css'
import deleteImg from "../../assets/img/delete-new.png"
const ActionDelete = ({to, onClick, children}) => {
  return (
    <Link
      to={to}
      className={`btn btn-edit`}
      onClick={onClick}
    >
      <img src={deleteImg} style={{width:22}} /> {children}
    </Link>
  );
};

ActionDelete.propTypes = {
to:PropTypes.string,
onClick: PropTypes.func,
children: PropTypes.string,
};
ActionDelete.defaultProps = {
    to:'#',
    onClick: () => {},
    children:''
  };
export default ActionDelete;
