import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getClientDetail} from "../../store/clients/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import BreadcrumbsClient from "components/Common/BreadcrumbClient";
import ClientBuyerBreadcrumbs from "components/Common/ClientBuyerBreadcrumb";
import ClientBuyerMenu from "./menu/client-buyer-menu";
import NoAccess from "components/NoAccess";
class ClientBuyerChecklist extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail} = this.props;
    onGetClientDetail(params.clientId);
    
   
 
}
  render() {
    const { clietDetail } = this.props;
    const hasBuyerType = clietDetail.clientTypes && clietDetail.clientTypes.some((type) => type.clientTypeCode === 1);
    return (
      <React.Fragment>
       <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          {hasBuyerType ? (
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientBuyerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(" Buyer Checklist ")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientBuyerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
              <Col xl="12">
              
			<Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    check1: (this.state && this.state.check1) || false,
                   
                    agentId: localStorage.getItem('agentId'),
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    // check1: Yup.string().required("This is Required"),
                    // mlsurl: Yup.string().required("This is Required"),
                    // listedPrice:Yup.number().typeError('Price should be a number').required("This is Required")
                  })}

                  onSubmit={this.handleAddListingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
                    <h5 className="card-title"></h5>
              
                  <Row>
                    <h4 className="mb-4">General Things</h4>
                    <Col  >
                    <Row>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check1"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check1"
                            checked
                          />
                          <Label htmlFor="check1" className="form-check-label">
                            {this.props.t("Buy boxes and other moving supplies")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check2"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check2"
                            checked
                          />
                          <Label htmlFor="check2" className="form-check-label">
                            {this.props.t("Double-check that your boxes are labeled correctly")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check3"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check3"
                            checked
                          />
                          <Label htmlFor="check3" className="form-check-label">
                            {this.props.t("Hire a moving company")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check4"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check4"
                          />
                          <Label htmlFor="check4" className="form-check-label">
                            {this.props.t("Take pictures or videos of every complex electronic connection")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check5"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check5"
                            checked
                          />
                          <Label htmlFor="check5" className="form-check-label">
                            {this.props.t("Empty your fridge")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check6"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check6"
                          />
                          <Label htmlFor="check6" className="form-check-label">
                            {this.props.t("Pack a bag with all essentials for moving day")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check7"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check7"
                            checked
                          />
                          <Label htmlFor="check7" className="form-check-label">
                            {this.props.t(" Have some cash-on-hand")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check8"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check8"
                          />
                          <Label htmlFor="check8" className="form-check-label">
                            {this.props.t("Clean your home")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check9"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check9"
                          />
                          <Label htmlFor="check9" className="form-check-label">
                            {this.props.t("Pack a first moving day box with first-day essentials")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check10"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check10"
                          />
                          <Label htmlFor="check10" className="form-check-label">
                            {this.props.t("Do a final walk through before exiting the home")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="check11"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="check11"
                          />
                          <Label htmlFor="check11" className="form-check-label">
                            {this.props.t("Take pictures of the empty home")}
                          </Label>
                      </FormGroup>
                      </Col>
                    </Row>
                    </Col>
                  </Row>

                  <hr className="mt-2 mb-3"/>

                  <Row>
                    <h4 className="mb-4">Change of address</h4>
                    <Col  >
                    <Row>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check1"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check1"
                            checked
                          />
                          <Label htmlFor="1check1" className="form-check-label">
                            {this.props.t("Inform post office to forward mail")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check2"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check2"
                          />
                          <Label htmlFor="1check2" className="form-check-label">
                            {this.props.t("Drivers license")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check3"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check3"
                          />
                          <Label htmlFor="1check3" className="form-check-label">
                            {this.props.t("health Card")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check4"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check4"
                            checked
                          />
                          <Label htmlFor="1check4" className="form-check-label">
                            {this.props.t("Insurance companies")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check5"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check5"
                          />
                          <Label htmlFor="1check5" className="form-check-label">
                            {this.props.t("Bank accounts & credit cards")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check6"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check6"
                          />
                          <Label htmlFor="1check6" className="form-check-label">
                            {this.props.t("Subscriptions Internet, TV and telecom service providers")}
                          </Label>
                      </FormGroup>
{/* 
                      <FormGroup className="mb-3">
                      <Label htmlFor="1check7" className="form-label">
                            {this.props.t(" Internet, TV and telecom service providers")}
                          </Label>
                          <Field
                            name="1check7"
                            onChange={handleChange}   
                            className='form-control me-2'  
                            
                            type="text"
                            id="1check7"
                          />
                      </FormGroup> */}
</Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="1check8"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="1check8"
                            checked
                          />
                          <Label htmlFor="1check8" className="form-check-label">
                            {this.props.t("Arrange to disconnect utilities at your old home and   connect utilities at your new home")}
                          </Label>
                      </FormGroup>
                      </Col>
                      </Row>
                    </Col>
                  </Row>

                  <hr className="mt-2 mb-3"/>

                  <Row>
                    <h4 className="mb-4">Tips for after you move in</h4>
                    <Col  >
                    <Row>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="3check1"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check1"
                            checked
                          />
                          <Label htmlFor="3check1" className="form-check-label">
                            {this.props.t("Take pictures/videos of the new place as soon as you walk in")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="3check2"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check2"
                            checked
                          />
                          <Label htmlFor="3check2" className="form-check-label">
                            {this.props.t("Ensure that you are present during the setup of your Internet and more!")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="3check3"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check3"
                          />
                          <Label htmlFor="3check3" className="form-check-label">
                            {this.props.t("Clean before unpacking")}
                          </Label>
                      </FormGroup>
                      </Col>
                        <Col sm='6'>  
                      <FormGroup className="mb-3">
                          <Field
                            name="3check4"
                            onChange={handleChange}   
                            className='form-check-input me-2'                         
                            type="checkbox"
                            id="3check4"
                            checked
                          />
                          <Label htmlFor="3check4" className="form-check-label">
                            {this.props.t("For Condo Owners")}
                          </Label>
                      </FormGroup>
                      </Col>
                      </Row>

                    </Col>
                  </Row>


                  

                
                </Form>
                  )}
                </Formik>
			 
			
			
            </Col>
            
            </Row>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
            </Col>
            </Row>
            ) : (
              <NoAccess />
             
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientBuyerChecklist.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
   

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientBuyerChecklist))
