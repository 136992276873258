import PropTypes from 'prop-types'
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import {userForgetPassword} from "../../store/actions"

import loginImg from "../../assets/img/agent-login.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
      this.props.userForgetPassword(values, event)
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="account-pages">
          <Container>
          <Row className="justify-content-center align-items-center vh-100">
              <Col md={10}>
                <div className="overflow-hidden my-5">
                 
                  <CardBody className="pt-4">
                    <Row>
                   
                      <Col lg={4}>
                      <div className="login-heading">
                    <h1>Forgot Password</h1>
                        </div>
                        <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            method="post"
                            onValidSubmit={this.handleValidSubmit}
                          >
                          
                            <div className="mb-3 label-white">
                            <AvField
                            name="userName"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                            </div>

                           

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-login btn-block"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>

                            <div className="mt-4 text-white">
                              <Link to="/login" className="text-white">
                                <i className="mdi mdi-lock me-1" /> Back to login
                              </Link>
                            </div>
                          </AvForm>
                        </div>
                      </Col>
                      <Col lg={2}></Col>
                      <Col md={6} lg={6} xl={5}>
                      <img src={loginImg} className="img-fluid" />
                    </Col>
                    </Row>
                  
                  </CardBody>
                </div>
                
              </Col>
             
            </Row>
         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.func,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any
}

const mapStateToProps = ({ForgetPassword}) => {
 
}
const mapDispatchToProps = dispatch => ({
  userForgetPassword: (data) => dispatch(userForgetPassword(data)),
 })
export default withRouter(
  connect('', mapDispatchToProps)(ForgetPasswordPage)
)
