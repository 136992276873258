import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, map } from "lodash";
import { withTranslation } from "react-i18next";
import EditLead from "./lead-edit";
import { Row,Col, Card, CardBody, Table, FormGroup, Label, Button, ButtonGroup,
  InputGroup, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getLeadDetail,  convertLeadIntoClient,
    convertLeadIntoContact, } from "../../../store/actions";
import RsLink from "components/buttons/rsLink";
class leadLeftDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRight: false,
      isRight2: false,
      isRight3: false,
      isBackdrop: false,
      leadId: "",
      Id: "",
      ccTab:"1"
    };
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
    this.toggleRightCanvas3 = this.toggleRightCanvas3.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }

  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleRightCanvas2() {
    this.setState({ isRight2: !this.state.isRight2 });
  }

  toggleRightCanvas3() {
    this.setState({ isRight3: !this.state.isRight3 });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  handleOnChange = value => {
    this.setState({
      volume: value,
    });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  cctoggle(tab) {
    if (this.state.ccTab !== tab) {
      this.setState({
        ccTab: tab,
      });
    }
  }

 
  componentDidMount() {
    const {
      match: { params },
      onGetLeadDetail,
    } = this.props;
    if (params && params.id && params.leadId) {
      onGetLeadDetail(params.leadId);
      this.setState({ leadId: params.leadId });
      this.setState({ Id: params.id });
    }
   
  }


  render() {
    const {leads, onConvertLeadToClient,onCovertLeadIntoContact} = this.props;
    const allLocal = localStorage.getItem("localData");
    const localization = JSON.parse(allLocal);
    const userModule = localStorage.getItem("userModule");
    //console.log(leads?.clientLinking?.contactId);
    return (
      
      <React.Fragment>          
                <Card className="overflow-hidden">
                 
                  <div className="pt-3">
                    <Row>
                      <Col sm="12">
                        <div className="pt-2">
                          <div className="text-center">
                          { leads?.clientLinking?.contactId < 1 ? (
                                <RsLink onClick={this.toggleRightCanvas3} className="btn-light-sm border-blue w-md" iconClass="">Save as Contact</RsLink> 
                                
                              ) : (
                                <RsLink onClick={this.toggleRightCanvas3} className="btn-light-sm border-blue w-md disabled" iconClass="">Save as Contact</RsLink>
                              )}
                              { leads?.clientLinking?.clientId < 1 ? (
                                <RsLink 
                                    onClick={this.toggleRightCanvas2} 
                                    className="btn-light-sm border-green w-md" 
                                    iconClass=""
                                >
                                    Save as Client
                                </RsLink>
                            ):(
                              <RsLink 
                              onClick={this.toggleRightCanvas2} 
                              className="btn-light-sm border-green w-md disabled" 
                              iconClass=""
                          >
                              Save as Client
                          </RsLink>
                            )}
                                {userModule.indexOf("BrokerAgent.RealtorLeads.Edit") >
                                -1 ? (
                                  <RsLink onClick={this.toggleRightCanvas} className="btn-purpul w-md" iconClass="">Edit</RsLink>
                                ) : (
                                  ""
                                )}
                          </div>
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="pt-2">
                          <div className="text-center">
                         
                              
                          
                          </div>
                        </div>
                      </Col>
                      
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody>
                    
                    <div className="clearfix"></div>
                    {!isEmpty(leads) && (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <th scope="row"> Name</th>
                              <td> {leads.title+ " "+ leads.firstName +" "+ leads.lastName} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Address</th>
                              <td> {leads?.leadAddress?.fullAddress}</td>
                            </tr>
                           
                            

                            <tr>
                              <th scope="row"> Phone</th>
                              <td> {leads.phone} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Mobile</th>
                              <td> {leads.mobile} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Email</th>
                              <td> {leads.email} </td>
                            </tr>
                            <tr>
                              <th scope="row">Source</th>
                              <td>
                                {" "}
                                {leads.leadSourceId
                                  ? localization[
                                      "LeadSource_" + leads.leadSourceId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Status</th>
                              <td>
                                {leads.leadStatusId
                                  ? localization[
                                      "LeadStatus_" + leads.leadStatusId
                                    ]
                                  : "N/A"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>
                                {" "}
                                {leads.leadTypeId
                                  ? localization["LeadType_" + leads.leadTypeId]
                                  : "N/A"}{" "}
                              </td>
                            </tr>


                            <tr>
                              <th scope="row">Stage</th>
                              <td>
                                {" "}
                                {leads.stageId ? localization["LeadStage_" + leads.stageId ] : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Group</th>
                              <td>
                                {" "}
                                {leads.group
                                  ? localization[
                                      "LeadGroup_" + leads.group
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Deal Value</th>
                              <td>
                                {leads.approxDealValue}
                               </td> 
                            </tr>
                            
                            <tr>
                              <th scope="row">Referred by</th>
                              <td>
                                {leads.referedBy}
                               </td> 
                            </tr>
                            <tr>
                              <th scope="row">Referral Code</th>
                              <td>
                                {leads.referralCode}
                               </td> 
                            </tr>
        
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Offcanvas
                  isOpen={this.state.isRight}
                  direction="end"
                  toggle={this.toggleRightCanvas}
                  style={{width:800}}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas}>
                    Update Lead
                  </OffcanvasHeader>
                  <OffcanvasBody>
                      <EditLead
                       closed={this.toggleRightCanvas} 
                       match={this.props.match}
                      />
                  </OffcanvasBody>
                </Offcanvas>

                <Offcanvas
                  isOpen={this.state.isRight2}
                  direction="end"
                  toggle={this.toggleRightCanvas2}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                    <h2 className="text-center">Review</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <h2 className="offcanvas-heading">Profile Info</h2>
                    <p className="offcanvas-info">
                      {" "}
                      To make any changes to profile Info, please Edit the Lead
                    </p>
                    <br />
                    <p className="form-label">
                      Name : {leads.firstName + " " + leads.lastName}{" "}
                    </p>
                    <p className="form-label">Phone : {leads.phone}</p>
                    <p className="form-label">Email : {leads.email} </p>
                    <p className="form-label">Address : {leads?.leadAddress?.fullAddress} </p>
                    <hr />
                    <h2 className="offcanvas-heading">
                      Type of Client (<small>Check all that apply</small>)
                    </h2>
                    {/* <h5  >Check all that apply</h5> */}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        
                        linkingId: 0,
                        contactId:0,
                        leadId: leads.id,
                        leadUUid: leads.leadId,
                        clientId: 0,
                        isBuyer: false,
                        isSeller: false,
                        isInvestor: false,
                        buyerType:0,
                        sellerType:0,
                        lastAgreementDate: new Date(),
                        userName:''
                      }}
                      validationSchema={Yup.object().shape({
                        userName: Yup.string()
                          .email("Invalid email format") 
                          .required("Email is required"),
                        confirmEmail: Yup.string()
                          .email("Invalid email format") 
                          .oneOf([Yup.ref("userName"), null], "Emails must match")
                          .required("Please confirm your email")
                      })}
                      onSubmit={values => {
                        const updateData = {
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                          leadId: parseInt(values.leadId),
                          leadUUid: values.leadUUid,
                          clientId: values.clientId,
                          isBuyer: values.isBuyer ? true : false,
                          isSeller: values.isSeller ? true : false,
                          isInvestor: values.isInvestor ? true : false,
                          buyerType:parseInt(values.buyerType),
                          sellerType:parseInt(values.sellerType),
                          lastAgreementDate:values.lastAgreementDate,
                          userName:values.userName
                        };
                        onConvertLeadToClient(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isBuyer"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isBuyer"
                                onClick={e => handleChange(e)}
                              />
                              <Label
                                htmlFor="isBuyer"
                                className="form-check-label"
                              >
                                {this.props.t("Buyer")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 1)}
                                  className={
                                    values.sellerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Rent
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 2)}
                                  className={
                                    values.sellerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Purchase
                                </Button>
                              </ButtonGroup>
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isSeller"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isSeller"
                                onClick={e => handleChange(e)}
                                // checked='true'
                              />
                              <Label
                                htmlFor="isSeller"
                                className="form-check-label"
                              >
                                {this.props.t("Seller")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              {/* <h5>Rnet </h5>
                            <Switch 
                            name="buyType"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={18}
                            width={50}
                             value={values.buyType}
                             onChange={(value) => setFieldValue('buyType', !value)}
                            />
                            <h5>Rnet </h5> */}
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 1)}
                                  className={
                                    values.buyerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Sell
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 2)}
                                  className={
                                    values.buyerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Lease
                                </Button>
                              </ButtonGroup>
                              {/* <Label htmlFor="buyType" className="form-check-label">
                              {this.props.t("purchase or Renat")}
                            </Label>  */}
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <FormGroup className="mb-3">
                            <Field
                              name="isInvestor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isInvestor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isInvestor"
                              className="form-check-label"
                            >
                              {this.props.t("Investor")}
                            </Label>
                          </FormGroup>
                          <hr />
                          <h2 className="offcanvas-heading">Agreement Info</h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="lastAgreementDate">
                              {this.props.t("Sign Date")}
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.lastAgreementDate && touched.lastAgreementDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.lastAgreementDate}
                                onChange={value =>
                                  setFieldValue("lastAgreementDate", value[0])
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </FormGroup>

                          <hr />

                          <h2 className="offcanvas-heading">
                            Client Login Info
                          </h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="userName">
                              {this.props.t("Login email")}
                            </Label>
                            <Field
                              name="userName"
                              type="text"
                              place
                              // placeholder="Login email"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.userName && touched.userName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="userName"
                            />
                            <ErrorMessage
                              name="userName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label htmlFor="confirmEmail">
                              {this.props.t("Confirm email")}
                            </Label>
                            <Field
                              name="confirmEmail"
                              type="text"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.confirmEmail && touched.confirmEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="confirmEmail"
                            />
                            <ErrorMessage
                              name="confirmEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <p className="offcanvas-info">
                            The login email cannot be changed at a later date
                          </p>
                          <p className="offcanvas-info">
                            A system generated password is sent to the client
                          </p>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
                <Offcanvas
                  isOpen={this.state.isRight3}
                  direction="end"
                  toggle={this.toggleRightCanvas3}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas3}>
                    <h2 className="text-center">Convert to Contact</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        leadUUid: leads.leadId,
                        contactTypeId: '',
                        contactFrequency:"",
                        contactIsVendor: false
                      }}
                      validationSchema={Yup.object().shape({
                        contactTypeId: Yup.number()
                          .required('Contact Type is required'),
                          
                        contactFrequency: Yup.string()
                          .required('Contact Frequency is required')
                          
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: parseInt(values.leadId),
                          leadUUid: values.leadUUid,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                          contactTypeId:parseInt(values.contactTypeId),
                          contactFrequency:values.contactFrequency,
                          contactIsVendor:values.contactIsVendor ? true : false,
                        };
                        onCovertLeadIntoContact(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                       

                          <FormGroup className="mb-3">
                            <Label htmlFor="name">{this.props.t("Name")}</Label>
                           
                            <Field
                              name="name"
                              type="text"
                              value={leads.firstName + " " + leads.lastName}
                            

                              className="form-control"
                              id="name"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="type"
                              className="form-label label-text"
                            >
                              Type
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.contactTypeId && touched.contactTypeId
                                  ? " is-invalid"
                                  : "")
                              }
                              name="contactTypeId"
                              id="type"
                            >
                              <option value="">Select</option>
                              <option value="9">Silver </option>
                              <option value="11">Gold</option>
                              <option value="10">VIP</option>
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="contactFrequency"
                              className="form-label label-text"
                            >
                              Contact frequency
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.contactFrequency && touched.contactFrequency
                                  ? " is-invalid"
                                  : "")
                              }
                              name="contactFrequency"
                              id="frequency"
                            >
                              <option value="">Select</option>
                              <option value="Monthly">Monthly </option>
                              <option value="Quatterly">Quatterly</option>
                              <option value="Bi-Annual">Bi-Annual</option>
                              <option value="Annual">Annual</option>
                            </Field>
                            <ErrorMessage
                              name="frequency"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              name="contactIsVendor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="contactIsVendor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="contactIsVendor"
                              className="form-check-label"
                            >
                              {this.props.t("Is a Vendor")}
                            </Label>
                          </FormGroup>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
      </React.Fragment>
    );
  }
}
leadLeftDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object,
  onConvertLeadToClient: PropTypes.func,
  onCovertLeadIntoContact: PropTypes.func,
};
const mapStateToProps = ({ leads }) =>
  ({
    leads: leads.leads,   
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  onConvertLeadToClient: data => dispatch(convertLeadIntoClient(data)),
  onCovertLeadIntoContact: data => dispatch(convertLeadIntoContact(data)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(leadLeftDetail)));
