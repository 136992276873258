export const GET_CONTACT = "GET_CONTACT"
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS"
export const GET_CONTACT_ERROR = "GET_CONTACT_ERROR"

export const ADD_CONTACT = "ADD_CONTACT"
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS"
export const ADD_CONTACT_ERROR = "ADD_CONTACT_ERROR"

export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const UPDATE_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS"
export const UPDATE_CONTACT_ERROR = "ADD_CONTACT_ERROR"


export const DELETE_CONTACT = "DELETE_CONTACT"
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS"
export const DELETE_CONTACT_ERROR = "DELETE_CONTACT_ERROR"

export const GET_CONTACT_DETAIL = "GET_CONTACT_DETAIL"
export const GET_CONTACT_DETAIL_SUCCESS = "GET_CONTACT_DETAIL_SUCCESS"
export const GET_CONTACT_DETAIL_FAIL = "GET_CONTACT_DETAIL_FAIL"


export const GET_BUYER_QUES_DETAIL = "GET_BUYER_QUES_DETAIL"
export const GET_BUYER_QUES_DETAIL_SUCCESS = "GET_BUYER_QUES_DETAIL_SUCCESS"
export const GET_BUYER_QUES_DETAIL_FAIL = "GET_BUYER_QUES_DETAIL_FAIL"


export const ADD_BUYER_QUES = "ADD_BUYER_QUES"
export const ADD_BUYER_QUES_SUCCESS = "ADD_BUYER_QUES_SUCCESS"
export const ADD_BUYER_QUES_FAIL = "ADD_BUYER_QUES_FAIL"




export const ADD_TAB_NOTE = "ADD_TAB_NOTE"
export const ADD_TAB_NOTE_SUCCESS = "ADD_TAB_NOTE_SUCCESS"
export const ADD_TAB_NOTE_FAIL = "ADD_TAB_NOTE_SUCCESS"



export const ADD_TAB_EMAIL = "ADD_TAB_EMAIL"
export const ADD_TAB_EMAIL_SUCCESS = "ADD_TAB_EMAIL_SUCCESS"
export const ADD_TAB_EMAIL_FAIL = "ADD_TAB_EMAIL_FAIL"



export const ADD_TAB_CALL = "ADD_TAB_CALL"
export const ADD_TAB_CALL_SUCCESS = "ADD_TAB_CALL_SUCCESS"
export const ADD_TAB_CALL_FAIL = "ADD_TAB_CALL_FAIL"


export const GET_TAB_CALL = "GET_TAB_CALL"
export const GET_TAB_CALL_SUCCESS = "GET_TAB_CALL_SUCCESS"
export const GET_TAB_CALL_FAIL = "GET_TAB_CALL_FAIL"


export const GET_TAB_EMAIL = "GET_TAB_EMAIL"
export const GET_TAB_EMAIL_SUCCESS = "GET_TAB_EMAIL_SUCCESS"
export const GET_TAB_EMAIL_FAIL = "GET_TAB_EMAIL_FAIL"


export const GET_TAB_NOTE = "GET_TAB_NOTE"
export const GET_TAB_NOTE_SUCCESS = "GET_TAB_NOTE_SUCCESS"
export const GET_TAB_NOTE_FAIL = "GET_TAB_NOTE_FAIL"


export const ADD_CONTACT_APP = "ADD_CONTACT_APP"
export const ADD_CONTACT_APP_SUCCESS = "ADD_CONTACT_APP_SUCCESS"
export const ADD_CONTACT_APP_FAIL = "ADD_CONTACT_APP_FAIL"


export const GET_CONTACT_APP = "GET_CONTACT_APP"
export const GET_CONTACT_APP_SUCCESS = "GET_CONTACT_APP_SUCCESS"
export const GET_CONTACT_APP_FAIL = "GET_CONTACT_APP_FAIL"


export const UPDATE_CONTACT_APP = "UPDATE_CONTACT_APP"
export const UPDATE_CONTACT_APP_SUCCESS = "UPDATE_CONTACT_APP_SUCCESS"
export const UPDATE_CONTACT_APP_FAIL = "UPDATE_CONTACT_APP_FAIL"

export const DELETE_CONTACT_APP = "DELETE_CONTACT_APP"
export const DELETE_CONTACT_APP_SUCCESS = "DELETE_CONTACT_APP_SUCCESS"
export const DELETE_CONTACT_APP_FAIL = "DELETE_CONTACT_APP_FAIL"

export const GET_CONTACT_APP_DETAIL = "GET_CONTACT_APP_DETAIL"
export const GET_CONTACT_APP_DETAIL_SUCCESS = "GET_CONTACT_APP_DETAIL_SUCCESS"
export const GET_CONTACT_APP_DETAIL_FAIL = "GET_CONTACT_APP_DETAIL_FAIL"

export const GET_ALL_CONTACT_ACTIVITY = "GET_ALL_CONTACT_ACTIVITY"
export const GET_ALL_CONTACT_ACTI_SUCCESS = "GET_ALL_CONTACT_ACTI_SUCCESS"
export const GET_ALL_CONTACT_ACTIVITY_FAIL = "GET_ALL_CONTACT_ACTIVITY_FAIL"


export const GET_CAMPAIGN_EMAIL_LIST_CONTACT = "GET_CAMPAIGN_EMAIL_LIST_CONTACT"
export const GET_CAMPAIGN_EMAIL_LIST_CONTACT_SUCCESS = "GET_CAMPAIGN_EMAIL_LIST_CONTACT_SUCCESS"
export const GET_CAMPAIGN_EMAIL_LIST_CONTACT_ERROR = "GET_CAMPAIGN_EMAIL_LIST_CONTACT_ERROR"


