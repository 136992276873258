import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormGroup } from "reactstrap";

class ProjectStatusGridOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: props.select || "", // Initialize from props
    };
  }

  handleChange = (e) => {
    const newStatus = e.target.value;
    this.setState({ selectedStatus: newStatus });

    // Notify the parent component about the change
    if (this.props.onStatusChange) {
      this.props.onStatusChange(newStatus);
    }
  };

  render() {
    const { docstatuses } = this.props;
    const { selectedStatus } = this.state;

    return (
      <FormGroup className="mb-3">
        <select
          name="projectStatus"
          className="form-control"
          value={selectedStatus} 
          onChange={this.handleChange} // Handle status change
        >
          <option value="">Select</option>
          {docstatuses.map((option) => (
            <option key={option.id} value={option.id}>
              {option.projectStatusTitle}
            </option>
          ))}
        </select>
      </FormGroup>
    );
  }
}

ProjectStatusGridOption.propTypes = {
  select: PropTypes.string,
  docstatuses: PropTypes.array.isRequired,
  onStatusChange: PropTypes.func, // Add prop type for the callback
};

export default ProjectStatusGridOption;
