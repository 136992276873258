import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import { getClientDetail} from "../../store/clients/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerMenu from "./menu/client-seller-menu";
import NoAccess from "components/NoAccess";
class ClientSellerCheck extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail} = this.props;
    onGetClientDetail(params.clientId);
    
   
 
}
  render() {
    const { clietDetail } = this.props;
    const hasSellerType = clietDetail.clientTypes && clietDetail.clientTypes.some((type) => type.clientTypeCode === 2);
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          {hasSellerType ? (
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Seller Checklist")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
              <Col xl="12">
              <AvForm className="needs-validation">
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                    <Row>
              
              
              <Col xl="12">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                      check1: (this.state && this.state.check1) || false,
                     
                      agentId: localStorage.getItem('agentId'),
                      clientId: localStorage.getItem('userId')
  
                    }}
                    validationSchema={Yup.object().shape({
                      // check1: Yup.string().required("This is Required"),
                      // mlsurl: Yup.string().required("This is Required"),
                      // listedPrice:Yup.number().typeError('Price should be a number').required("This is Required")
                    })}
  
                    onSubmit={this.handleAddListingSubmit}
                  >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                {/* <Card className="mini-stats-wid"> 
                      <CardBody> */}
                      <h5 className="card-title"></h5>
  
                      <Row>
                      {/* <h4>Tips for after you move in</h4> */}
                      <Col  >
                      <Row>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="3check1"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="3check1"
                              checked='true'
                            />
                            <Label htmlFor="3check1" className="form-check-label">
                              {this.props.t("Clean the House")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="3check2"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="3check2"
                              checked='true'
                            />
                            <Label htmlFor="3check2" className="form-check-label">
                              {this.props.t("Close All Valves and Turn Off All Switches")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="3check3"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="3check3"
                            />
                            <Label htmlFor="3check3" className="form-check-label">
                              {this.props.t("Don't Skip the Final Walk-Through")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="3check4"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="3check4"
                            />
                            <Label htmlFor="3check4" className="form-check-label">
                              {this.props.t("Check cabinets, drawers, and storage areas for any forgotten items")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="3check5"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="3check5"
                            />
                            <Label htmlFor="3check5" className="form-check-label">
                              {this.props.t("Leave all house keys, remotes, gate keys, pool keys, and mailbox keys for the new owner.")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="3check6"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="3check6"
                            />
                            <Label htmlFor="3check6" className="form-check-label">
                              {this.props.t("Assemble a packet of appliance manuals, receipts, and any warranties as well.")}
                            </Label>
                        </FormGroup>
                        </Col>
                        </Row>
  
                      </Col>
                    </Row>
  
                    <hr className="mb-2 mt-2"/>
                
                    <Row>
                      <h4 className="mb-4">General Things</h4>
                      <Col  >
                      <Row>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check1"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check1"
                              checked='true'
                            />
                            <Label htmlFor="check1" className="form-check-label">
                              {this.props.t("Buy boxes and other moving supplies")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check2"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check2"
                              checked='true'
                            />
                            <Label htmlFor="check2" className="form-check-label">
                              {this.props.t("Double-check that your boxes are labeled correctly")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check3"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check3"
                            />
                            <Label htmlFor="check3" className="form-check-label">
                              {this.props.t("Hire a moving company")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check4"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check4"
                            />
                            <Label htmlFor="check4" className="form-check-label">
                              {this.props.t("Take pictures or videos of every complex electronic connection")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check5"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check5"
                              checked='true'
                            />
                            <Label htmlFor="check5" className="form-check-label">
                              {this.props.t("Empty your fridge")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check6"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check6"
                              checked='true'
                            />
                            <Label htmlFor="check6" className="form-check-label">
                              {this.props.t("Pack a bag with all essentials for moving day")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check7"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check7"
                            />
                            <Label htmlFor="check7" className="form-check-label">
                              {this.props.t(" Have some cash-on-hand")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check8"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check8"
                              checked='true'
                            />
                            <Label htmlFor="check8" className="form-check-label">
                              {this.props.t("Clean your home")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check9"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check9"
                            />
                            <Label htmlFor="check9" className="form-check-label">
                              {this.props.t("Pack a first moving day box with first-day essentials")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check10"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check10"
                            />
                            <Label htmlFor="check10" className="form-check-label">
                              {this.props.t("Do a final walk through before exiting the home")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="check11"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="check11"
                            />
                            <Label htmlFor="check11" className="form-check-label">
                              {this.props.t("Take pictures of the empty home")}
                            </Label>
                        </FormGroup>
                        </Col>
                        </Row>
                      </Col>
                    </Row>
  
                    <hr className="mt-2 mb-3"/>
  
                    <Row>
                      <h4 className="mb-4">Change of address</h4>
                      <Col  >
                      <Row>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="1check1"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="1check1"
                              checked='true'
                            />
                            <Label htmlFor="1check1" className="form-check-label">
                              {this.props.t("Inform post office to forward mail")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="1check2"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="1check2"
                              checked='true'
                            />
                            <Label htmlFor="1check2" className="form-check-label">
                              {this.props.t("Drivers license")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="1check3"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="1check3"
                            />
                            <Label htmlFor="1check3" className="form-check-label">
                              {this.props.t("health Card")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="1check4"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="1check4"
                              checked='true'
                            />
                            <Label htmlFor="1check4" className="form-check-label">
                              {this.props.t("Insurance companies")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="1check5"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="1check5"
                            />
                            <Label htmlFor="1check5" className="form-check-label">
                              {this.props.t("Bank accounts & credit cards")}
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col sm='6'>  
                        <FormGroup className="mb-3">
                            <Field
                              name="1check6"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="1check6"
                              checked='true'
                            />
                            <Label htmlFor="1check6" className="form-check-label">
                              {this.props.t("Subscriptions Internet, TV and telecom service providers")}
                            </Label>
                        </FormGroup>
  
                        {/* <FormGroup className="mb-3">
                        <Label htmlFor="1check7" className="form-label">
                              {this.props.t(" Internet, TV and telecom service providers")}
                            </Label>
                            <Field
                              name="1check7"
                              onChange={handleChange}   
                              className='form-control me-2'  
                              
                              type="text"
                              id="1check7"
                            />
                        </FormGroup> */}
                        </Col>
            
                        </Row>
                      </Col>
                    </Row>
  
                    {/* <Row>
                      <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} type="submit" className="btn btn-primary w-md">Save</button></div></Col>
                    </Row> */}
                    
               
               {/* </CardBody>
                  </Card> */}
                  
                  </Form>
                    )}
                  </Formik>
         
        
        
              </Col>
              
              </Row>
					 
                    
                   {/* <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div> */}
             
             </CardBody>
                </Card>
				
				
                
             </AvForm>
			 
			
			
            </Col>
            
            </Row>
            </Col>
            </Row>
             ) : (
              <NoAccess />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSellerCheck.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
   

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientSellerCheck))
