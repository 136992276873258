import {
  ADD_CONTACT, ADD_CONTACT_ERROR, ADD_CONTACT_SUCCESS, GET_CONTACT_DETAIL, GET_CONTACT_DETAIL_FAIL, GET_CONTACT_DETAIL_SUCCESS,
  GET_BUYER_QUES_DETAIL, GET_BUYER_QUES_DETAIL_SUCCESS, GET_BUYER_QUES_DETAIL_FAIL, ADD_BUYER_QUES, ADD_BUYER_QUES_SUCCESS, 
  ADD_BUYER_QUES_FAIL, ADD_TAB_NOTE, ADD_TAB_NOTE_SUCCESS, ADD_TAB_NOTE_FAIL, 
  ADD_TAB_EMAIL, ADD_TAB_EMAIL_SUCCESS, ADD_TAB_EMAIL_FAIL, ADD_TAB_CALL, 
  ADD_TAB_CALL_SUCCESS, ADD_TAB_CALL_FAIL, GET_TAB_CALL, 
  GET_TAB_CALL_SUCCESS, GET_TAB_CALL_FAIL, GET_TAB_EMAIL, 
  GET_TAB_EMAIL_SUCCESS, GET_TAB_EMAIL_FAIL, GET_TAB_NOTE, 
  GET_TAB_NOTE_SUCCESS, GET_TAB_NOTE_FAIL, ADD_CONTACT_APP, 
  ADD_CONTACT_APP_SUCCESS, ADD_CONTACT_APP_FAIL, GET_CONTACT_APP, 
  GET_CONTACT_APP_SUCCESS, GET_CONTACT_APP_FAIL, GET_CONTACT_APP_DETAIL, 
  GET_CONTACT_APP_DETAIL_SUCCESS, GET_CONTACT_APP_DETAIL_FAIL,
  GET_ALL_CONTACT_ACTIVITY, GET_ALL_CONTACT_ACTI_SUCCESS, GET_ALL_CONTACT_ACTIVITY_FAIL, ADD_OPHOUSE_LEAD, ADD_OPHOUSE_LEAD_SUCCESS, ADD_OPHOUSE_LEAD_FAIL,
  GET_CONTACT,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_ERROR,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_ERROR,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT_APP,
  UPDATE_CONTACT_APP_SUCCESS,
  UPDATE_CONTACT_APP_FAIL,
  DELETE_CONTACT_APP,
  DELETE_CONTACT_APP_SUCCESS,
  DELETE_CONTACT_APP_FAIL,
  GET_CAMPAIGN_EMAIL_LIST_CONTACT,
  GET_CAMPAIGN_EMAIL_LIST_CONTACT_SUCCESS,
  GET_CAMPAIGN_EMAIL_LIST_CONTACT_ERROR
} from "./actionTypes"


export const getContacts = (agentId) => ({
  type: GET_CONTACT,
  agentId
})
export const getContactsSuccess = contacts => ({
  type: GET_CONTACT_SUCCESS,
  payload: contacts
})
export const getContactsFail = error => ({
  type: GET_CONTACT_ERROR,
  payload: error,
})

export const addContact = (contact, history) => {
  return {
    type: ADD_CONTACT,
    payload: { contact, history },
  }
}

export const addContactSuccess = contact => {
  return {
    type: ADD_CONTACT_SUCCESS,
    payload: contact,
  }
}
export const contactapiError = error => {
  return {
    type: ADD_CONTACT_ERROR,
    payload: error,
  }
}

export const deleteContact = id => ({
  type: DELETE_CONTACT,
  payload: id,
})

export const deleteContactSuccess = lead => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: lead,
})

export const deleteContactFail = error => ({
  type: DELETE_CONTACT_ERROR,
  payload: error,
})


export const updateContact = lead => (
  {
    type: UPDATE_CONTACT,
    payload: lead,
  })

export const updateContactSuccess = lead => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: lead,
})

export const updateContactFail = error => ({
  type: UPDATE_CONTACT_ERROR,
  payload: error,
})


export const addBuyerQuest = (bquestion, history) => {
  return {
    type: ADD_BUYER_QUES,
    payload: { bquestion, history },
  }
}

export const addBuyerQuestSucess = bquestion => {
  return {
    type: ADD_BUYER_QUES_SUCCESS,
    payload: bquestion,
  }

}


export const bqapiError = error => {
  return {
    type: ADD_BUYER_QUES_FAIL,
    payload: error,
  }
}
export const getContactSuccess = contacts => ({
  type: GET_CONTACT_DETAIL_SUCCESS,
  payload: contacts,
})

export const getContactDetail = contactId => ({
  type: GET_CONTACT_DETAIL,
  contactId,
})

export const getContactDetailSuccess = contacts => ({
  type: GET_CONTACT_DETAIL_SUCCESS,
  payload: contacts,
})

export const getContactDetailFail = error => ({
  type: GET_CONTACT_DETAIL_FAIL,
  payload: error,
})


export const getbuyerQuestion = questionFor => ({
  type: GET_BUYER_QUES_DETAIL,
  questionFor,
})

export const getbuyerQuestionSuccess = questions => ({
  type: GET_BUYER_QUES_DETAIL_SUCCESS,
  payload: questions,
})

export const getbuyerQuestionFail = error => ({
  type: GET_BUYER_QUES_DETAIL_FAIL,
  payload: error,
})


export const addTabNote = (notes, history) => {
  return {
    type: ADD_TAB_NOTE,
    payload: { notes, history },
  }
}

export const addTabNoteSucess = notes => {
  return {
    type: ADD_TAB_NOTE_SUCCESS,
    payload: notes,
  }

}


export const tabNotError = error => {
  return {
    type: ADD_TAB_NOTE_FAIL,
    payload: error,
  }
}



export const addTabEmail = (notes, history) => {
  return {
    type: ADD_TAB_EMAIL,
    payload: { notes, history },
  }
}

export const addTabEmailSucess = notes => {
  return {
    type: ADD_TAB_EMAIL_SUCCESS,
    payload: notes,
  }
}


export const tabEmailError = error => {
  return {
    type: ADD_TAB_EMAIL_FAIL,
    payload: error,
  }
}


export const addTabCall = (notes, history) => {
  return {
    type: ADD_TAB_CALL,
    payload: { notes, history },
  }
}

export const addTabCallSucess = notes => {
  return {
    type: ADD_TAB_CALL_SUCCESS,
    payload: notes,
  }
}


export const tabCalError = error => {
  return {
    type: ADD_TAB_CALL_FAIL,
    payload: error,
  }
}

export const getTabCall = contactId => (
  {
    type: GET_TAB_CALL,
    contactId,
  })

export const getCalltabSuccess = callLogs => ({
  type: GET_TAB_CALL_SUCCESS,
  payload: callLogs,
})

export const getCalltabFail = error => ({
  type: GET_TAB_CALL_FAIL,
  payload: error,
})


export const getTabEmail = contactId => (
  {
    type: GET_TAB_EMAIL,
    contactId,
  })

export const getEmailtabSuccess = emailLogs => ({
  type: GET_TAB_EMAIL_SUCCESS,
  payload: emailLogs,
})

export const getEmailtabFail = error => ({
  type: GET_TAB_EMAIL_FAIL,
  payload: error,
})



export const getTabNote = contactId => (
  {
    type: GET_TAB_NOTE,
    contactId,
  })

export const getNotetabSuccess = noteLogs => ({
  type: GET_TAB_NOTE_SUCCESS,
  payload: noteLogs,
})

export const getNotetabFail = error => ({
  type: GET_TAB_NOTE_FAIL,
  payload: error,
})



export const addContactAPP = (appointment, history) => {
  return {
    type: ADD_CONTACT_APP,
    payload: { appointment, history },
  }
}


export const addContactAPPSuccess = appointment => ({
  type: ADD_CONTACT_APP_SUCCESS,
  payload: appointment,
})

export const addContactAPPFail = error => ({
  type: ADD_CONTACT_APP_FAIL,
  payload: error,
})

export const getAppContatTab = (agentId, ContactId) => (
  //console.log(ContactId),
  {
    type: GET_CONTACT_APP,
    payload: { agentId, ContactId },
  })

export const getAppContatTabSuccess = appointments => ({
  type: GET_CONTACT_APP_SUCCESS,
  payload: appointments,
})

export const getAppContatTabFail = error => ({
  type: GET_CONTACT_APP_FAIL,
  payload: error,
})


export const updateAppContactTab = appointment => (
  {
    type: UPDATE_CONTACT_APP,
    payload: appointment,
  })

export const updateAppContactTabSuccess = appointment => ({
  type: UPDATE_CONTACT_APP_SUCCESS,
  payload: appointment,
})

export const updateAppContactTabFail = error => ({
  type: UPDATE_CONTACT_APP_FAIL,
  payload: error,
})


export const deleteAppContactTab = id => ({
  type: DELETE_CONTACT_APP,
  payload: id,
})

export const deleteAppContactTabSuccess = appointment => ({
  type: DELETE_CONTACT_APP_SUCCESS,
  payload: appointment,
})

export const deleteAppContactTabFail = error => ({
  type: DELETE_CONTACT_APP_FAIL,
  payload: error,
})




export const getAppContatDetailTab = id => (
  //console.log(agentID),
  {
    type: GET_CONTACT_APP_DETAIL,
    id,
  })

export const getAppContatTabDetailSuccess = appDetails => ({
  type: GET_CONTACT_APP_DETAIL_SUCCESS,
  payload: appDetails,
})

export const getAppContatTabDetailFail = error => ({
  type: GET_CONTACT_APP_DETAIL_FAIL,
  payload: error,
})


export const getAllContactActivityTab = contactId => (
  //console.log(agentID),
  {
    type: GET_ALL_CONTACT_ACTIVITY,
    contactId,
  })

export const getAllContactActivityTabSuccess = allactivities => ({
  type: GET_ALL_CONTACT_ACTI_SUCCESS,
  payload: allactivities,
})

export const getAllContactActivityTabFail = error => ({
  type: GET_ALL_CONTACT_ACTIVITY_FAIL,
  payload: error,
})


export const getCampContactList = (agentId, contactId) => (
  {
    type: GET_CAMPAIGN_EMAIL_LIST_CONTACT,
    payload: { agentId, contactId },
  })

export const getCampContactListSuccess = camplists => ({
  type: GET_CAMPAIGN_EMAIL_LIST_CONTACT_SUCCESS,
  payload: camplists,
})

export const getCampContactListFail = error => ({
  type: GET_CAMPAIGN_EMAIL_LIST_CONTACT_ERROR,
  payload: error,
})