import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import * as moment from 'moment';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAllProjectClient, addClientNote, getClientNote, deleteClientNote, addClientAppointment } from "../../../store/actions";
import { API_URL } from "../../../helpers/app_url";
import axios from "axios"
import smlogo from "../../../assets/img/rs-logo-sm.jpg"
import {
  Col,
  Container,
  Row,
   Card,
  CardBody,
  Label,
  Input,
    FormGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    CardFooter,
    CardTitle,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    InputGroup
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import RsCard from "components/Rscards/Rscard";
import RSBarChart from "components/charts/RSBarChart";
import RSPieChart from "components/charts/RSPieChart";
import RsAdNew from "components/buttons/Adnew";
import SaveButton from "components/buttons/save";
import RsLink from "components/buttons/rsLink";
import blocks from "plugins/projectOverview/blocks";
import Moment from "react-moment";

class ProjectClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
        client:'',
        appTypes: [],
        appPriories: [],
        appLocTypes: [],
        appointment:'',
        checked: true,
          reports: [
            { title: "# Clients", iconClass: "bx bx-copy-alt", description: "78" },
           
            {
              title: "# Lead to client conversion success",
              iconClass: "bx bx-purchase-tag-alt",
              description: "38 %",
            },
            {
              title: "Client Acquistion cost",
              iconClass: "bx bx-purchase-tag-alt",
              description: "$400",
            },
         
          ]

      }
    this.toggleEmailCanvas = this.toggleEmailCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.toggleNoteCanvas = this.toggleNoteCanvas.bind(this);
    this.toggleAppointmentCanvas = this.toggleAppointmentCanvas.bind(this); 
    this.handleNoteSubmit = this.handleNoteSubmit.bind(this)
    this.handelValidAppointment = this.handelValidAppointment.bind(this)
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.toggleAssignmentNew = this.toggleAssignmentNew.bind(this);
  }
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });
    
  }
  toggleAssignmentNew() {
    this.setState({ isAssignmentNew: !this.state.isAssignmentNew });
    
  }
  handleNoteSubmit(value){
    this.props.addClientNote(value, this.props.history);
  }
  handelValidAppointment(value){
    //console.log(value)
    this.props.addClientAppointment(value, this.props.history);

  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
toggleEmail() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    })); 
}
toggleNote() {
    this.setState(prevState => ({
        isNote: !prevState.isNote
    })); 
}
toggleApp() {
    this.setState(prevState => ({
        isApp: !prevState.isApp
    })); 
}
toggleEmailCanvas() {
    this.setState({ client: "", isEmailEdit: false, isRight: !this.state.isRight });
    this.toggleEmail();
}
toggleNoteCanvas() {
    this.setState({ client: "", isNoteEdit: false, isNote: !this.state.isNote });
    this.toggleEmail();
}
toggleAppointmentCanvas() {
    this.setState({ client: "", isAppEdit: false, isApp: !this.state.isApp });
    this.toggleEmail();
}
  componentDidMount() {
   const { ongGetAllProjectClient } = this.props;
   ongGetAllProjectClient(localStorage.getItem('userId'));
   const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(API_URL + 'AppointmentType/all', {
      headers: { Authorization: str }
    })
      .then(res => {
        const appTypes = res.data;
        this.setState({ appTypes });
      }),
      axios.get(API_URL + 'AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get(API_URL + 'AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        })
}
toggleEmailCanvas = arg => {
    const client = arg;
    this.setState({
        client: {
          clientId: client.clientId,

        },
        isEmailEdit: true,
    });
    this.toggleEmail();
};

toggleNoteCanvas = arg => {
    const client = arg;
    this.setState({
        client: {
          clientId: client.clientId,

        },
        isEmailEdit: true,
    });
    this.toggleNote();
};

toggleAppointmentCanvas = arg => {
    const client = arg;
    this.setState({
        client: {
          clientId: client.clientId,
            firstName:client.firstName,
            lastName:client.lastName,

        },
        isAppEdit: true,
    });
    this.toggleApp();
};
handleSearch = (event) => {
  this.setState({ searchQuery: event.target.value });
};

getFilteredClients = () => {
  const { allclients } = this.props;
  const { searchQuery } = this.state;

  if (!searchQuery) {
      return allclients;
  }

  return allclients.filter((client) => {
      const query = searchQuery.toLowerCase();
      return (
          (client.firstName + " " + client.lastName).toLowerCase().includes(query) ||
          client.phone?.toLowerCase().includes(query) ||
          client.email?.toLowerCase().includes(query)
      );
  });
};

  
  render() {
    const { allclients } = this.props;
    const client = this.state.client;
    const selectedValue = '';
    const filteredClients = this.getFilteredClients();
    const barchartdata = {
      series: [
        {
          name: "Clients",
          data: [3, 8, 16],
        },
        {
          name: "Marketing Spend",
          data: [6, 14, 18],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
      
        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8", "#41B8D5"],
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar"
          ],
        },
      },
    };
    const leadSourceData = {
      series: [20, 20, 20, 20, 20],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["Item 5", "Google", "Facebook", "Item 3",  "Item 4"],
        colors: ["#31356E", "#6CE5E8", "#41B8D5", "#2D8BBA", "#2F5F98"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            {/* Render Breadcrumbs */}
           
           <Row className="mb20 project-header">
              <Col lg="12">
              <Breadcrumbs
              title={this.props.t("Projects")}
              breadcrumbItem={this.props.t("Projects Clients")}
            />
              </Col>
	
             {/* <Col lg="6">
            
             </Col> */}
           </Row>
		 
           <Row className="mb20" style={{marginTop:20}}> 
            {this.state.reports.map((report, key) => (
            <Col md="4" className="" key={key}>
                <RsCard title={report.title} description={report.description} iconClass={report.iconClass} />
              </Col>
            ))}
            </Row>

            <Row className="mb20">
            <Col lg={4}>
            <RSBarChart title="" filter={false}  height="370"  series={barchartdata.series} options={barchartdata.options} />
            </Col>
            <Col lg="4">
            <RSPieChart title="Sources" filter={false} height="385" series={leadSourceData.series} options={leadSourceData.options} />
            </Col>
            <Col lg={4}>
            <div className="card">
            <div className="card-body">
                <div className="card-title mb-4 float-start">Top 5 Project</div>
                <Table
                      id=""
                      className="table table-striped table-bordered"
                      >
                        <Tbody>
                          <tr>
                            <td>Motto</td>
                            <td>Sierra</td>
                            <td># 50 Clients</td>
                          </tr>
                          <tr>
                            <td>Mirabella Luxury Condominiums</td>
                            <td>Mirabella Development Corporation</td>
                            <td># 18 Clients</td>
                          </tr>
                          <tr>
                            <td>Joya on the Queensway</td>
                            <td>Altree Developments</td>
                            <td># 18 Clients</td>
                          </tr>
                          <tr>
                            <td>Kazmir Condos</td>
                            <td>Lormel Homes</td>
                            <td># 18 Clients</td>
                          </tr>
                          <tr>
                            <td>Queen Church</td>
                            <td>Tridel and BAZIS</td>
                            <td># 18 Clients</td>
                          </tr>
                        </Tbody>
                </Table>
                </div>
            </div>
                    </Col>
            </Row>

              <Card>
                <CardBody>
              <Row>
           
              <Col lg="12">
              <h5 className="card-title">All</h5>
                                              <div className="row mb20">
                                          
                                              <div className="col-sm-3"></div>
                                              <div className="col-sm-4 mb20">
                                                        
                                                        <Input type="text" className="form-control" id="autoSizingInput"
                                                        value={this.state.searchQuery}
                                                        onInput={this.handleSearch}
                                                        placeholder="Search By Client Name, Phone, Email" />
                                              </div>
                                            
                                            
                                              <div className="col-sm-3"></div>
                                          <div className="col-sm-2 float-end">
                                          <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass=" bx bx-send">Send Project Updates</RsAdNew>
                                          
                                          </div>
                                              </div>
       
        
                                              <div className="clearfix"></div>
                                              <div className="table-rep-plugin">
                                                <div
                                                    className=""
                                                    data-pattern="priority-columns"
                                                >
                                                    <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped table-bordered"
                                                    >
                                                    <Thead>
                                                        <Tr>
                                                      
                                                        <Th>{this.props.t("Name")}</Th>
                                                        <Th data-priority="3">{this.props.t("Project")}</Th>
                                                        <Th data-priority="1">{this.props.t("Date")}</Th>
                                                        <Th data-priority="3">{this.props.t("Phone")}</Th>
                                                        <Th data-priority="3">{this.props.t("Email")}</Th>
                                                        <Th data-priority="3">{this.props.t("Client Login")}</Th>
                                                        <Th data-priority="3"></Th>
                                                    
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                 
                                                    {map(filteredClients, (client, clientkey) => (
                                                        <Tr key={clientkey}>
                                                        
                                                        <Td>
                                                        <div className="row">
                    <div className="col-sm-8" >{client.firstName + " " + client.lastName}</div>
                          
                          <div className="col-sm-1"><Link  to="#" onClick={() => this.toggleEmailCanvas(client)}><i className="grid-icon bx bx-mail-send"></i></Link></div>
                          <div className="col-sm-1"><Link  to="#" onClick={() => this.toggleNoteCanvas(client)}><i className="grid-icon bx bxs-message-alt-dots"></i></Link></div>
                          <div className="col-sm-1"><Link  to="#" onClick={() => this.toggleAppointmentCanvas(client)}> <i className="grid-icon  bx bx-calendar"></i></Link></div>
                        
                    </div>
                                                            
                                                        </Td>
                                                      
                                                        <Td>{client?.project ? client?.project?.projectName : '' } </Td>
                                                        <Td>{client.lastContact ?<Moment format="D MMM YY">{client.lastContact}</Moment> : '' }</Td>
                                                        <Td> { client.phone }   </Td>
                                                       
                                                        <Td> { client.email } </Td>
                                                        <Td> <Link to={"/clients/dashbord/" + client.clientId + '/' + client.clientGuid} className="btn-primary waves-effect waves-light btn-sm">Dashboard</Link> </Td>
                                                        <Td>

                                                        <UncontrolledDropdown direction="up">
                                        <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                          Action <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem  href="#">View client</DropdownItem>
                                          <DropdownItem onClick={ this.toggleAssignmentNew }  href="#">Assignment Sale</DropdownItem>


                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                                        </Td>
                                                       
                                                        </Tr>
                                                    ))}  
                                                        
                                                    </Tbody>
                                                    </Table>
                                                </div>
                                                </div>   
                
              </Col>
             
            </Row>
            </CardBody>      
            </Card>
                                                
          </Container>

          <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleEmailCanvas}
                            style={{width:800}}
                        >
                            <OffcanvasHeader toggle={this.toggleEmailCanvas}>
                                Send Email
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                
                            </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas
                            isOpen={this.state.isNote}
                            direction="end"
                            toggle={this.toggleNoteCanvas}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleNoteCanvas}>
                               Add Note
                            </OffcanvasHeader>
                            <OffcanvasBody>
                            <Formik
                            enableReinitialize={true}
                            initialValues={{
                                noteTitle: (this.state && this.state.noteTitle) || "",
                                agentId: localStorage.getItem('userId'),
                                clientId: client.clientId
                            }}
                            validationSchema={Yup.object().shape({
                                noteTitle: Yup.string().max(1000, "Message Text must not exceed 1000").required("This is Required")
                            })}

                  onSubmit={this.handleNoteSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            
                            <textarea
                              name="noteTitle"
                              onChange={handleChange}
                              type="textarea"
                              className={
                                "form-control" +
                                (errors.noteTitle && touched.noteTitle
                                  ? " is-invalid"
                                  : "")
                              }
                              id="noteTitle"
                            />
                            <ErrorMessage
                              name="noteTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                    </Form>
                  )}
                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas
                            isOpen={this.state.isApp}
                            direction="end"
                            toggle={this.toggleAppointmentCanvas}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleAppointmentCanvas}>
                               Add Appointment
                            </OffcanvasHeader>
                            <OffcanvasBody>
                            <Formik
                    enableReinitialize={true}
                    initialValues={{
                      appointmentTitle: (this.state && this.state.appointmentTitle) || "",
                      appointmentStartDate: (this.state && this.state.appointmentStartDate) || "",
                      startTime: (this.state && this.state.startTime) || "",
                      endTime: (this.state && this.state.endTime) || "",
                      contactName: client.firstName +" " + client.lastName,
                      contactPhone: '1234567890',
                      contactEmail: 'mohit@esimplified.ca',
                      clientId: client.clientId,
                      agentId: localStorage.getItem('userId'),
                      addedBy: 'agent'

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().required("This is Required"),
                      appointmentStartDate: Yup.string().required("This is Required"),
                      startTime: Yup.string().required("This is Required"),
                      endTime: Yup.string().required("This is Required")
                    })}

                    onSubmit={this.handelValidAppointment}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Agenda")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                value={values.appointmentTitle}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                
                                
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: false,
                                  minDate: "today",
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="mb15">

                              <Label htmlFor="startTime">
                                {this.props.t("Start Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className={
                                    "form-control" +
                                    (errors.startTime && touched.startTime
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Select time"
                                 
                                  onChange={(value) => setFieldValue('startTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                                <ErrorMessage
                                name="startTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                              </InputGroup>
                              
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                               className={
                                "form-control" +
                                (errors.endTime && touched.endTime
                                  ? " is-invalid"
                                  : "")
                              }
                                  placeholder="Select time"
                                  onChange={(value) => setFieldValue('endTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                                <ErrorMessage
                                name="endTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                              </InputGroup>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentType">
                                {this.props.t("Appointment Type")}
                              </Label>
                              <Field as="select" name="appointmentType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appTypes
                                    .map(appType =>
                                      <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentPriority">
                                {this.props.t("Priority")}
                              </Label>
                              <Field as="select" name="appointmentPriority" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appPriories
                                    .map(appPriorie =>
                                      <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentLocationType">
                                {this.props.t("Location Type")}
                              </Label>
                              <Field as="select" name="appointmentLocationType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appLocTypes
                                    .map(appLocType =>
                                      <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">
                              {(() => {
                                if (selectedValue == 3) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Location")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else if (selectedValue == 2) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Phone")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Online Link / Address")}
                                      </Label>
                                      
                                    </>
                                  )
                                }
                              })()}
                              <Field
                              name="appointmentLocation"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.appointmentLocation && touched.appointmentLocation
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="appointmentLocation"
                            />
                               


                            </FormGroup>

                          </Col>
                        </Row>




                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>
                      </Form>
                    )}
                  </Formik>   
                            </OffcanvasBody>
                        </Offcanvas>



                        <Offcanvas
                        isOpen={this.state.isAddNew}
                        direction="end"
                        toggle={this.toggleAddNew}
                       
                      >
                        <OffcanvasHeader toggle={this.toggleAddNew}>
                          Send Project Updates
                        </OffcanvasHeader>
                        <OffcanvasBody>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                noteTitle: (this.state && this.state.noteTitle) || "",
                                agentId: localStorage.getItem('userId'),
                                clientId: client.clientId
                            }}
                            validationSchema={Yup.object().shape({
                                noteTitle: Yup.string().max(1000, "Message Text must not exceed 1000").required("This is Required")
                            })}

                  onSubmit={this.handleNoteSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                      <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentType">
                                {this.props.t("Select Project")}
                              </Label>
                              <Field as="select" name="appointmentType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                               
                              </Field>

                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <h5># Client : 10</h5>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Email Subject")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                          <FormGroup className="mb-3">
                          <label style={{display:blocks, width:"100%"}}>
                                {this.props.t("Email Body")}

                                <div className="aitext" style={{float:"right", marginRight:20}}><img src={smlogo} /> A.I Assist</div>
                              </label>
                            <textarea
                              name="noteTitle"
                              onChange={handleChange}
                              type="textarea"
                              className={
                                "form-control" +
                                (errors.noteTitle && touched.noteTitle
                                  ? " is-invalid"
                                  : "")
                              }
                              id="noteTitle"
                            />
                            <ErrorMessage
                              name="noteTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <SaveButton iconClass=" bx bx-mail-send">Send</SaveButton>{" "}
                      <RsLink iconClass=" bx bx-mail-send">Send Preview email</RsLink>{ " " }
                      <RsLink>Cancel</RsLink>

                    </Form>
                  )}
                </Formik>
                        </OffcanvasBody>
                        </Offcanvas>


                        <Offcanvas
                        isOpen={this.state.isAssignmentNew}
                        direction="end"
                        toggle={this.toggleAssignmentNew}
                       
                      >
                        <OffcanvasHeader toggle={this.toggleAssignmentNew}>
                        Assignment Sale
                        </OffcanvasHeader>
                        <OffcanvasBody>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                noteTitle: (this.state && this.state.noteTitle) || "",
                                agentId: localStorage.getItem('userId'),
                                clientId: client.clientId
                            }}
                            validationSchema={Yup.object().shape({
                                noteTitle: Yup.string().max(1000, "Message Text must not exceed 1000").required("This is Required")
                            })}

                  onSubmit={this.handleNoteSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                   

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Asking Price")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Original Purchase Price")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Deposits Paid")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>     
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Deposit Requirement")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>   
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Development Cap")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>   
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Upgrades")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>    
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Builders Occupancy Fees")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>    
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Condo/POTL Fees")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>   
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Property Taxes")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col> 
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Total Occupancy Fees")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>    
                      </Row>
                      <SaveButton>Submit</SaveButton>{" "}
                     

                    </Form>
                  )}
                </Formik>
                        </OffcanvasBody>
                        </Offcanvas>

        </div>
      </React.Fragment>
    )
  }
}

ProjectClients.propTypes = {
  
  className: PropTypes.any,
  t: PropTypes.any,
  history:PropTypes.object,
  ongGetAllProjectClient:PropTypes.func,
  allclients:PropTypes.array,
  loading:PropTypes.object,
  match:PropTypes.object,
  addClientNote:PropTypes.func,
  addClientAppointment:PropTypes.func
  
}

const mapStateToProps = ({ Project }) => ({
    allclients : Project.allclients,
    loading: Project.loading
})

const mapDispatchToProps = dispatch => ({
    ongGetAllProjectClient: (agentId) => dispatch(getAllProjectClient(agentId)),
    addClientNote: (data) => dispatch(addClientNote(data)),
    addClientAppointment: (data) => dispatch(addClientAppointment(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectClients)))