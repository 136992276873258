import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import DeleteModal from "../../../components/Common/DeleteModal";
import FileBase64 from "../../../components/Common/FileBase64";
import { addProjectMedia, getProjectMedia, deleteProjectDocument, getProjectUUIDAction  } from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import { BlobServiceClient } from '@azure/storage-blob';
import Swal from 'sweetalert2'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  TabContent,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";
import RsAdNew from "components/buttons/Adnew";
import SaveButton from "components/buttons/save";
import ActionDelete from "components/buttons/actionDelete";


class ProjectsMedia extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      file: null,
      docTypes:[],
      isRight:false,
      fileUploaded: false,
    }
   
    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleProjectDocumentSubmit = this.handleProjectDocumentSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
  }
  async handleFileChange(event, setFieldValue) {
    const file = event.currentTarget.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      const allowedTypes = ['application/pdf', 'application/msword', 'image/jpeg', 'image/png'];
      const allowedExtensions = ['.pdf', '.doc', '.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
      if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          title: 'File type or extension not allowed',
          text: 'Please upload a PDF, DOC, JPG, or PNG file.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        //alert('Invalid file type. Please upload a PDF, DOC, JPG, or PNG file.');
        return;
      }
      if (file.size > maxSize) {
        Swal.fire({
          title: 'Size not allowed',
          text: 'Please Upload Maximum 5MB File',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
       
      }
      this.setState({ btnloading: true });
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-06-22T02:18:02Z&st=2024-06-21T18:18:02Z&spr=https,http&sig=Srg7JmJ7EfJQWUNadO188IgXNeCFEzSmQQmctuyjGxA%3D";
      const containerName = "images";
      const storageAccountName = "realtyspace";
      const fileName = `${Date.now()}_${file.name}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });

     
        if (setFieldValue) {
          const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
          setFieldValue(`projectDocumentLink`, fileUrl);
          setFieldValue(`projectDocumentContent`, fileUrl);
          this.setState({ fileUploaded: true });
        }
      } catch (error) {
        console.error('Error uploading file:', error);

      }
    }
  }

  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
    
  }
  handleProjectDocumentSubmit(value){

    this.props.addProjectMedia(value, this.props.history);
    this.toggle();
    //console.log(value)

  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (document) => {
    this.setState({ document: document });
    this.setState({ deleteModal: true });
  };

  handleDeleteMedia = () => {
    const { onDeleteMedia } = this.props;
    const { document } = this.state;
    //console.log(document);
    if (document.id !== undefined) {
      onDeleteMedia(document);
      this.setState({ deleteModal: false });
    }
  };
  componentDidMount() {
    const { match: { params }, onGetProjectMedia, ongetProjectUUIDAction }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectMedia(params.id)
      ongetProjectUUIDAction(params.projectId);
    }else{
      this.props.history.push('/my-project')
    }

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/ProjectDocumentType/all', { headers: { Authorization: str } })
      .then(res => {
        const docTypes = res.data;
        this.setState({ docTypes });
      })
  }
  render() {
    //console.log(this.state.docTypes);
    const { documents,projectData } = this.props;
    const { deleteModal } = this.state;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { fileUploaded } = this.state;
      return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteMedia}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

        <MetaTags>
          <title>Campaigns | Realty Space</title>
        </MetaTags>
        <Container fluid className="ps-0 ">
              <Row>
                  <Col sm='2' className="pe-0" >
                  <ProjectSidebar/>
                  </Col>
              <Col sm='10' className="ps-0">
              <Row className="project-header">
                <Col sm="7">
              <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Document")} />
              </Col>
              <Col sm="4">
              <h2 className="">{this.props.t( projectData.projectName)}</h2>
              </Col>
              </Row>
              <SetupMenu />
                      <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                     
                    <h5 className="card-title">
                        <span className="float-end">
                        <RsAdNew onClick={this.toggleRightCanvas} className="btn-light float-end mb20"> Upload Document</RsAdNew>
                      
                        </span>

                    </h5>
                    <div className="clearfix"></div>
					<div className="table-responsive">
						

              <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={documents} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  {/* <Column  field="lastContact" header="Type" body={(rowData) => (
                      localization["ProjectDocumentType_"+ rowData.projectDocumentTypeId]
                  )}></Column> */}
                  <Column  field="projectDocumentTitle" header="Title" ></Column>
                  <Column  field="lastContact" header="Link" body={(rowData) => (
                      <a target="_blank" rel="noreferrer" href={rowData.projectDocumentLink}>View</a>
                  )}></Column>
                  <Column  field="lastContact" header="" body={(rowData) => (
                      <ActionDelete  onClick={() => this.onClickDelete(rowData)} />
                    )}></Column>
                  
              </DataTable>

						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>      
      </Col>
      </Row>   

              <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
              Add Documents
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                          enableReinitialize={true}
                          initialValues={{
                            projectDocumentTitle: (this.state && this.state.projectDocumentTitle) || "",
                            projectDocumentTypeId: 0,
                            projectId: this.props.match.params.id,
                            agentId: localStorage.getItem('userId'),
                            //projectDocumentContent:this.state.files.base64

                          }}
                  validationSchema={Yup.object().shape({
                    projectDocumentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit={this.handleProjectDocumentSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
         
                
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field
                              name="projectDocumentTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.projectDocumentTitle && touched.projectDocumentTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectDocumentTitle"
                            />
                             <ErrorMessage
                              name="projectDocumentTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentTypeId">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="projectDocumentTypeId" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                {
                                            this.state.docTypes
                                              .map(docType =>
                                                <option key={docType.id} value={docType.id}>{docType.projectDocumentTypeTitle}</option>
                                              )
                                          }
                            </Field>
                          </FormGroup>
                        </Col> */}
                        <Col sm="12">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">File upload</Label>
                          <input
                                    type="file"
                                    className="form-control"
                                    onChange={event => this.handleFileChange(event, setFieldValue)}
                                  />
                          
                          
                        </div>
                       
                        </Col>

                       
                        
                        <div className="clearfix">
                        {fileUploaded && <SaveButton>Upload</SaveButton>}
                          
                          </div>
                        
                    </Row>

                
           
                
            </Form>
                  )}
			              </Formik>

              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsMedia.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectMedia:PropTypes.func,
  documents:PropTypes.array,
  onGetProjectMedia:PropTypes.func,
  match:PropTypes.object,
  onDeleteMedia:PropTypes.func,
  loading:PropTypes.object,
  ongetProjectUUIDAction:PropTypes.func,
  projectData: PropTypes.object,


 
}

const mapStateToProps = ({ Project }) => (
{
  documents: Project.documents,
  loading:Project.loading,
  projectData: Project.projectData,
})

const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectMedia: (data) => dispatch(addProjectMedia(data)),
  onGetProjectMedia:(projectId) => dispatch(getProjectMedia(projectId)),
  onDeleteMedia: (id) => dispatch(deleteProjectDocument(id))

})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsMedia)))