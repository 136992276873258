import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
import {  isEmpty, map, size } from "lodash";
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import { getClientDetail, addClientSellerShwoing, getClientSellerListing, getClientSellerShwoing, 
  updateClientSellerShowing, deleteClientSellerShowing, getTransStatusType, getTransCommType, 
  addClientSellerOffer, getClientSellerOffer, updateClientSellerOffer} from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import BreadcrumbsClient from "../../components/Common/BreadcrumbClient";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerMenu from "./menu/client-seller-menu";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import NoAccess from "components/NoAccess";
class ClientSellerShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showing:''
    }
    this.handleSellerShowingSubmit = this.handleSellerShowingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleSend = this.toggleSend.bind(this);
  
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (showing) => {
    this.setState({ showing: showing });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }

  toggle2() {
    this.setState(prevState => ({
      isRight2: !prevState.isRight2
    }));
  }

  handleDeleteShowing = () => {
    const { onDeleteShowing } = this.props;
    const { showing } = this.state;
    
    if (showing.id !== undefined) {
      onDeleteShowing(showing);
      this.setState({ deleteModal: false });
    }
  };
  handleSellerShowingSubmit(value){
    //console.log(value);
    //this.props.addClientSellerShwoing(value, this.props.history);
    this.toggleSend();
  }
  componentDidMount() {
    const {  match: { params }, onGetClientDetail, onGetClientSellerListing, onGetClientSellerShowing, onGetTransactonStatusType, onGetTransactonCommType } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientSellerListing(params.id);
    onGetClientSellerShowing(params.id);
    onGetTransactonStatusType(localStorage.getItem('userId'), 'Seller');
    onGetTransactonCommType(localStorage.getItem('userId'));
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { showings } = this.props;
  if (!isEmpty(showings) && size(prevProps.showings) !== size(showings)) {
    this.setState({ showings: {}, isEdit: false });
  }
}
toggleSend(){
  this.setState({ isSend: !this.state.isSend });
  //this.toggle();
}
toggleRightCanvas() {
  this.setState({ showing: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}

toggleRightCanvas2() {
  this.setState({ showing: "", isEdit: false, isRight2: !this.state.isRight2 });
  this.toggle2();
  console.log("asda")
}

toggleRightCanvas = arg => {
  const showing = arg;

  this.setState({
    showing: {
  
      id:showing.id,
      clientSellerShowingId: showing.clientSellerShowingId,
      clientId:showing.clientId,
      agentId: showing.agentId,
      clientListingId: showing.clientListingId,
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: showing.agentName,
      agentOrganization: showing.agentName,
      agentContact: showing.agentContact,
      agentEmail: showing.agentEmail,
      feedback:showing.feedback,
      notificationMinutes: showing.notificationMinutes,
      isActive: true
    },
    isEdit: true,
  });
  this.toggle();
};

toggleRightCanvas2 = arg => {
  const showing = arg;
  const { onGetClientSellerOffer } = this.props;
  onGetClientSellerOffer(showing.id);
  this.setState({
    showing: {
  
      id:showing.id,
      clientSellerShowingId: showing.clientSellerShowingId,
      clientId:showing.clientId,
      agentId: showing.agentId,
      clientListingId: showing.clientListingId,
      showingDate: showing.showingDate,
      startTime: showing.startTime,
      endTime: showing.endTime,
      agentName: showing.agentName,
      agentOrganization: showing.agentName,
      agentContact: showing.agentContact,
      agentEmail: showing.agentEmail,
      feedback:showing.feedback,
      notificationMinutes: showing.notificationMinutes,
      isActive: true
    },
    isEdit: true,
  });
  this.toggle2();
 
};
handlePlaceSelected = (address, setFieldValue) => {
  geocodeByAddress(address)
    .then(results => {
      const place = results[0];
      const addressComponents = place.address_components;
      
      const getComponent = (types) => {
        const component = addressComponents.find((component) =>
          types.every((type) => component.types.includes(type))
        );
        return component ? component.long_name : '';
      };
      setFieldValue(`agentOfficeAddress`, place.formatted_address);

    })
    .catch(error => console.error('Error', error));
};
offerSubmitted = (values) => {
  const payload = {
    agentId : parseInt(localStorage.getItem('userId')),
    clientId: parseInt(this.props.match.params.id),
    partyName: values.partyName,
    partyAgentInfo:values.partyAgentInfo,
    agentName:values.agentName,
    agentMobile:values.agentMobile,
    agentEmail:values.agentEmail,
    agentOffice:values.agentOffice,
    agentOfficeName:values.agentOfficeName,
    agentOfficeAddress:values.agentOfficeAddress,
    agentOfficePhone:values.agentOfficePhone,
    transTypeId:1,
    commissionTypeId:parseInt(values.commissionTypeId),
    commisisonValue:parseInt(values.commisisonValue),
    askingPrice:parseInt(values.askingPrice),
    offerPrice:parseInt(values.offerPrice), 
    offerDate:values.offerDate, 
    closingDate:values.closingDate,
    refId:parseInt(values.refId),
    refTypeId:2,
    statusId:parseInt(values.statusId),
    isAccepted:'',
    conditions:'',
    notes:values.notes
}
    
    this.props.addClientSellerOffer(payload);
}
offerUpdated = (values) => {
 
  const payload = {
    id:parseInt(values.id),
    offerId:values.offerId,
    agentId : parseInt(localStorage.getItem('userId')),
    clientId: parseInt(this.props.match.params.id),
    partyName: values.partyName,
    partyAgentInfo:values.partyAgentInfo,
    agentName:values.agentName,
    agentMobile:values.agentMobile,
    agentEmail:values.agentEmail,
    agentOffice:values.agentOffice,
    agentOfficeName:values.agentOfficeName,
    agentOfficeAddress:values.agentOfficeAddress,
    agentOfficePhone:values.agentOfficePhone,
    transTypeId:1,
    commissionTypeId:parseInt(values.commissionTypeId),
    commisisonValue:parseInt(values.commisisonValue),
    askingPrice:parseInt(values.askingPrice),
    offerPrice:parseInt(values.offerPrice), 
    offerDate:values.offerDate, 
    closingDate:values.closingDate,
    refId:parseInt(values.refId),
    refTypeId:2,
    statusId:parseInt(values.statusId),
    isAccepted:'',
    conditions:'',
    notes:values.notes
}
this.props.updateClientSellerOffer(payload);
}
  render() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const { listings, showings, clietDetail, onUpdateClientSellerShowing, loading, transStatusTypes, transactionCommTypes, selleroffer } = this.props;
    const showing = this.state.showing;
    const { isEdit, deleteModal } = this.state;
    const hasSellerType = clietDetail.clientTypes && clietDetail.clientTypes.some((type) => type.clientTypeCode === 2);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteShowing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          {hasSellerType ? (
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( " Showing")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    //clientListingId: (this.state && this.state.clientListingId) || "",
                    clientListingId: (this.state && this.state.clientListingId) || "",
                    agentName: (this.state && this.state.agentName) || "",
                    agentOrganization: (this.state && this.state.agentOrganization) || "",
                    agentContact: (this.state && this.state.agentContact) || "",
                    agentEmail: (this.state && this.state.agentEmail) || "",
                    showingDate: new Date(),
                    startTime: new Date(),
                    endTime: edTime,
                    agentId: localStorage.getItem('userId'),
                    clientId: this.props.match.params.id


                  }}
                  validationSchema={Yup.object().shape({
                    clientListingId: Yup.string().required("This is Required"),
                    // agentName: Yup.string().required("This is Required"),
                    // agentOrganization: Yup.string().required("This is Required"),
                    // agentOrganization: Yup.string().required("This is Required"),
                    // agentContact: Yup.string().matches(
                    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    //   "Enter valid Contact number"
                    // ).required(
                    //   "Please Enter Contact Number"
                    // ),
                    // agentEmail: Yup.string().email("Must be a valid Email")
                    // .max(255)
                    // .required("Email is required")
                  })}

                  onSubmit={this.handleSellerShowingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Card className="newCard">
                        <CardBody>
                          <h5 className="card-title pheading">Add Seller Showing</h5>

                          <Row>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Listing *")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="showingDate">
                                  {this.props.t("Date *")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time *")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                   
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time *")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>
                          </Row>
                          <h5 className="card-title">Seller Agent Detail (Optional)</h5> 

                           <Row>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentName">
                                  {this.props.t("Agent Name")}
                                </Label>
                                <Field
                                  name="agentName"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentName && touched.agentName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentName"
                                />
                                <ErrorMessage
                                  name="agentName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentOrganization">
                                  {this.props.t("Agent Organization")}
                                </Label>
                                <Field
                                  name="agentOrganization"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentOrganization && touched.agentOrganization
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentOrganization"
                                />
                                <ErrorMessage
                                  name="agentOrganization"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentContact">
                                  {this.props.t("Agent Contact")}
                                </Label>
                                <Field
                                  name="agentContact"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentContact && touched.agentContact
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentContact"
                                />
                                <ErrorMessage
                                  name="agentContact"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentEmail">
                                  {this.props.t("Agent Email")}
                                </Label>
                                <Field
                                  name="agentEmail"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentEmail && touched.agentEmail
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentEmail"
                                />
                                <ErrorMessage
                                  name="agentEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>  

                            <SaveButton btnClass="btn btn-save mt-27">Save</SaveButton>     
                        </CardBody>
                      </Card>

                    

                    </Form>
                  )}
                </Formik>

                <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Showing</h5>
					<div className="">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Listing</th>
								<th>Date Time</th>
                <th>Agent Name</th>
                <th>Agent Organization</th>
                <th>Agent Contact</th>
                <th>Agent Email</th>
                <th>Status </th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(showings, (showing, showkey) => (
							 <tr key={"_list_" + showkey}>
                  <th>{ showkey + 1 }</th>
                  <td>{showing?.clientListing?.mlsId}</td>
                  <td><Moment format="Do MMMM YYYY">{showing.showingDate}</Moment> / <Moment format="hh:mm A">{showing.startTime}</Moment>-<Moment format="hh:mm A">{showing.endTime}</Moment></td>
                  <td>{showing.agentName}</td>
                  <td>{showing.agentOrganization}</td>
                  <td>{showing.agentContact}</td>
                  <td>{showing.agentEmail}</td>
                  <td>
                  <select  className="form-control" >
                    <option value="">Awaiting Response</option>
                    <option value="">Not Interested</option>
                    <option value="">Offer Recieved</option>
                                
                </select>
                </td>
                  <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                      <DropdownItem onClick={() => this.toggleRightCanvas2(showing)} href="#">Offer Details</DropdownItem>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(showing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(showing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td>

               </tr>
              ))}
             
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>                  

              </Col>

            </Row>
            </Col>
            </Row>
           ) : (
            <NoAccess />
           
          )}
                    <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:showing.id,
                    clientSellerShowingId: showing.clientSellerShowingId,
                    clientId:showing.clientId,
                    agentId: showing.agentId,
                    clientListingId: showing.clientListingId,
                    showingDate: showing.showingDate,
                    startTime: showing.startTime,
                    endTime: showing.endTime,
                    agentName: showing.agentName,
                    agentOrganization: showing.agentName,
                    agentContact: showing.agentContact,
                    agentEmail: showing.agentEmail,
                    feedback:showing.feedback,
                    notificationMinutes: showing.notificationMinutes,
                    isActive: true


                  }}
                  validationSchema={Yup.object().shape({
                    //clientListingId: Yup.string().required("This is Required"),
                    //agentName: Yup.string().required("This is Required"),
                    //agentOrganization: Yup.string().required("This is Required"),
                    //agentOrganization: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateShowing = {
                        id:values.id,
                        clientSellerShowingId: values.clientSellerShowingId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        clientListingId: parseInt(values.clientListingId),
                        showingDate: values.showingDate,
                        startTime: values.startTime,
                        endTime: values.endTime,
                        agentName: values.agentName,
                        agentOrganization: values.agentName,
                        agentContact: values.agentContact,
                        agentEmail: values.agentEmail,
                        feedback:values.feedback,
                        notificationMinutes: values.notificationMinutes,
                        isActive: true
                      };

                      onUpdateClientSellerShowing(updateShowing);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                          <h5 className="card-title">Showing Detail</h5>

                          <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Listing")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="showingDate">
                                  {this.props.t("Date")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.showingDate}
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.startTime}
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.endTime}
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>
                          </Row>

                       
                          <h5 className="card-title">Seller Agent Detail (Optional)</h5>

                          <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentName">
                                  {this.props.t("Agent Name")}
                                </Label>
                                <Field
                                  name="agentName"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentName && touched.agentName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentName"
                                />
                                <ErrorMessage
                                  name="agentName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentOrganization">
                                  {this.props.t("Agent Organization")}
                                </Label>
                                <Field
                                  name="agentOrganization"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentOrganization && touched.agentOrganization
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentOrganization"
                                />
                                <ErrorMessage
                                  name="agentOrganization"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentContact">
                                  {this.props.t("Agent Contact")}
                                </Label>
                                <Field
                                  name="agentContact"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentContact && touched.agentContact
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentContact"
                                />
                                <ErrorMessage
                                  name="agentContact"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="agentEmail">
                                  {this.props.t("Agent Email")}
                                </Label>
                                <Field
                                  name="agentEmail"
                                  onChange={handleChange}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.agentEmail && touched.agentEmail
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="agentEmail"
                                />
                                <ErrorMessage
                                  name="agentEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                         <Row>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="feedback">
                                  {this.props.t("Feedback")}
                                </Label>
                                <Field
                              name="feedback"
                              onChange={handleChange}
                              as="textarea"
                              className={
                                "form-control" +
                                (errors.feedback && touched.feedback
                                  ? " is-invalid"
                                  : "")
                              }
                              id="feedback"
                            />
                           
                                <ErrorMessage
                                  name="feedback"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>


                          </Row> 
                          <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Update</button></div>

                       

                    </Form>
                  )}
                </Formik>

                      </OffcanvasBody>
                    </Offcanvas>

                   
                    <Offcanvas
                      isOpen={this.state.isRight2}
                      direction="end"
                      toggle={this.toggleRightCanvas2}
                      style={{width:600}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                        Offer Details 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:(selleroffer?.id) ? selleroffer?.id : 0,
                    offerId:(selleroffer?.offerId) ? selleroffer?.offerId : '',
                    partyName : (selleroffer?.partyName) ? selleroffer?.partyName : '',
                    partyAgentInfo:  (selleroffer?.partyAgentInfo) ? selleroffer?.partyAgentInfo : '',
                    agentName:(selleroffer?.agentName) ? selleroffer?.agentName : showing.agentName,
                    agentMobile: (selleroffer?.agentMobile) ? selleroffer?.agentMobile :  showing.agentContact,
                    agentEmail: (selleroffer?.agentEmail) ? selleroffer?.agentEmail : showing.agentEmail,
                    agentOffice: (selleroffer?.agentOffice) ? selleroffer?.agentOffice : '',
                    agentOfficeName: (selleroffer?.agentOfficeName) ? selleroffer?.agentOfficeName : '',
                    agentOfficeAddress: (selleroffer?.agentOfficeAddress) ? selleroffer?.agentOfficeAddress :'',
                    agentOfficePhone: (selleroffer?.agentOfficePhone) ? selleroffer?.agentOfficePhone :'',
                    transTypeId: 1,
                    commissionTypeId: (selleroffer?.commissionTypeId) ? selleroffer?.commissionTypeId : 0,
                    commisisonValue: (selleroffer?.commisisonValue) ? selleroffer?.commisisonValue : 0,
                    askingPrice: (selleroffer?.askingPrice) ? selleroffer?.askingPrice : 0,
                    offerPrice: (selleroffer?.askingPrice) ? selleroffer?.askingPrice :0,
                    offerDate:(selleroffer?.offerDate) ? selleroffer?.offerDate: new Date(),
                    closingDate: (selleroffer?.closingDate) ? selleroffer?.closingDate: new Date(),
                    refId: (selleroffer?.refId) ? selleroffer?.refId : showing.id,
                    refTypeId:2,
                    statusId: (selleroffer?.statusId) ? selleroffer?.statusId : 0,
                    isAccepted:'',
                    conditions:'',
                    notes:(selleroffer?.notes) ? selleroffer?.notes : 0,
                  }}
                  validationSchema={Yup.object().shape({
                    
                  })}
                  onSubmit={(values) => {
                    if (selleroffer && selleroffer.id) {
                      this.offerUpdated(values); 
                    } else {
                      this.offerSubmitted(values);
                    }
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

             <Row>
                      <Col sm="12"><h5><strong>Offer Info</strong></h5></Col>
                      <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="statusId">
                                {this.props.t("Offer Status")}
                              </Label>
                              <Field as="select" name="statusId"
                                      className={
                                        "form-control" +
                                        (errors.statusId && touched.statusId
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(transStatusTypes, (transStatusType, classkey) => (
                                      <option value={transStatusType.id} key={classkey}>{transStatusType.title}</option>
                                    ))}
                                    
                                    </Field>
                              <ErrorMessage
                                name="statusId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="offerDate">
                                    {this.props.t("Offer Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.offerDate && touched.offerDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.offerDate}
                                    onChange={(value) => setFieldValue('offerDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                                </Col>
                                <Col sm="4">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="closingDate">
                                    {this.props.t("Closing Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.closingDate && touched.closingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.closingDate}
                                    onChange={(value) => setFieldValue('closingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                                </Col>
                                <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="askingPrice">
                              {this.props.t("Asking Price")}
                            </Label>
                            <Field
                              name="askingPrice"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.askingPrice && touched.askingPrice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="askingPrice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="offerPrice">
                              {this.props.t("Final Price")}
                            </Label>
                            <Field
                              name="offerPrice"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.offerPrice && touched.offerPrice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="offerPrice"
                            />
                             <ErrorMessage
                              name="offerPrice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12"><h5><strong>Agent info (optional)</strong></h5></Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentName">
                              {this.props.t("Name")}
                            </Label>
                            <Field
                              name="agentName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.agentName && touched.agentName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="agentName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentMobile">
                              {this.props.t("Phone")}
                            </Label>
                            <Field
                              name="agentMobile"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.agentMobile && touched.agentMobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="agentMobile"
                            />
                             <ErrorMessage
                              name="agentMobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentEmail">
                              {this.props.t("Email")}
                            </Label>
                            <Field
                              name="agentEmail"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.agentEmail && touched.agentEmail
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="agentEmail"
                            />
                             <ErrorMessage
                              name="agentEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentOffice">
                              {this.props.t("Office")}
                            </Label>
                            <Field
                              name="agentOffice"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.agentOffice && touched.agentOffice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="agentOffice"
                            />
                             <ErrorMessage
                              name="mlsId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentOfficeName">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="agentOfficeName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.agentOfficeName && touched.agentOfficeName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="agentOfficeName"
                            />
                             <ErrorMessage
                              name="agentOfficeName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentOfficeName">
                              {this.props.t("Office Email")}
                            </Label>
                            <Field
                              name="agentOfficeName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.agentOfficeName && touched.agentOfficeName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="agentOfficeName"
                            />
                             <ErrorMessage
                              name="agentOfficeName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentOfficeAddress">
                              {this.props.t("Agent office  address")}
                            </Label>
                            <PlacesAutocomplete
                              value={values.agentOfficeAddress}
                              onChange={(value) => setFieldValue(`agentOfficeAddress`, value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                             <ErrorMessage
                              name="agentOfficeAddress"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12"><h5><strong>Agent info (optional)</strong></h5></Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="partyName">
                              {this.props.t("Name")}
                            </Label>
                            <Field
                              name="partyName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.partyName && touched.partyName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="partyName"
                            />
                             <ErrorMessage
                              name="partyName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="partyAgentInfo">
                              {this.props.t("Buyer Agent info")}
                            </Label>
                            <Field
                              name="partyAgentInfo"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.partyAgentInfo && touched.partyAgentInfo
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="partyAgentInfo"
                            />
                             <ErrorMessage
                              name="partyAgentInfo"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                     
                       
                    
                        {/* <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commissionTypeId">
                              {this.props.t("Commision Type")}
                            </Label>
                            <Field as="select" name="commissionTypeId" id="commissionTypeId"  className={
                                    "form-control" +
                                    (errors.commision_type && touched.commision_type
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={handleChange}>
                                <option value="">Select</option>
                                {map(transactionCommTypes, (transactionCommType, classkey) => (
                                <option value={transactionCommType.id} key={classkey}>{transactionCommType.title}</option>
                              ))}
                               
                              </Field>
                             <ErrorMessage
                              name="commissionTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>


                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commisisonValue">
                              {this.props.t("Commission value ")}
                            </Label>
                            <Field
                              name="commisisonValue"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.commisisonValue && touched.commisisonValue
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="commisisonValue"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col> */}
                        

                       
                              
                                          
                        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="notes">
                              {this.props.t("Note")}
                            </Label>
                            <textarea
                              name="notes"
                              onChange={handleChange}
                              type="textarea"
                              value={values.notes}
                              className={
                                "form-control" +
                                (errors.notes && touched.notes
                                  ? " is-invalid"
                                  : "")
                              }
                              id="notes"
                            />
                             <ErrorMessage
                              name="notes"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        <Col sm="12">
                        <div className="clearfix">
                        <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                          </div>
                        </Col>
                    </Row>
                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>

                    <Offcanvas
                      isOpen={this.state.isSend}
                      direction="end"
                      toggle={this.toggleSend}
                    >
                      <OffcanvasHeader toggle={this.toggleSend}>
                        Send Document 
                      </OffcanvasHeader>
                      <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   

                  }}
                  validationSchema={Yup.object().shape({
                    documentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="documentTitle">
                              {this.props.t("Subject")}
                            </Label>
                            <Field
                              name="emailSubject"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.emailSubject && touched.emailSubject
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="emailSubject"
                            />
                             <ErrorMessage
                              name="emailSubject"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup>
                              {/* <h6 style={{ marginTop: 30 }}>

                                <strong>{this.props.t("Content")}</strong>
                              </h6> */}
                              <Label htmlFor="projectAdditionalFeatureDescription">Email Content</Label>
                              <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('emailContent', data);
                                }}

                              />
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                        
                        <UpdateButton iconClass="bx bx-send">{this.props.t("Send")}</UpdateButton>{ " " }
                        
                        </Col>
                    </Row>

                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSellerShow.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientSellerShwoing:PropTypes.func,
  onGetClientSellerListing:PropTypes.func,
  listings:PropTypes.array,
  onGetClientSellerShowing:PropTypes.func,
  showings:PropTypes.array,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  onDeleteShowing:PropTypes.func,
  onUpdateClientSellerShowing:PropTypes.func,
  loading: PropTypes.object,
  onGetTransactonStatusType:PropTypes.func,
  transStatusTypes: PropTypes.array,
  onGetTransactonCommType: PropTypes.func,
  transactionCommTypes: PropTypes.array,
  addClientSellerOffer:PropTypes.func,
  onGetClientSellerOffer:PropTypes.func,
  selleroffer:PropTypes.object,
  updateClientSellerOffer:PropTypes.func
}
const mapStateToProps = ({ Clients, AppoinmentObject }) => (
  {
     clietDetail: Clients.clietDetail,
     listings:Clients.listings,
     showings:Clients.showings,
     loading: Clients.loading,
     transStatusTypes: AppoinmentObject.transStatusTypes,
     transactionCommTypes: AppoinmentObject.transactionCommTypes,
     selleroffer:Clients.selleroffer
  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerShwoing: (data) => dispatch(addClientSellerShwoing(data)),
  onGetClientSellerListing:(clientId) => dispatch(getClientSellerListing(clientId)),
  onGetClientSellerShowing:(clientId) => dispatch(getClientSellerShwoing(clientId)),
  onUpdateClientSellerShowing:(data) => dispatch(updateClientSellerShowing(data)),
  onDeleteShowing: id    =>              dispatch(deleteClientSellerShowing(id)),
  onGetTransactonStatusType: (agentId, forType) => dispatch(getTransStatusType(agentId, forType)),
  onGetTransactonCommType: (agentId) => dispatch(getTransCommType(agentId)),
  addClientSellerOffer:(data) => dispatch(addClientSellerOffer(data)),
  onGetClientSellerOffer:(refId) => dispatch(getClientSellerOffer(refId)),
  updateClientSellerOffer:(data) => dispatch(updateClientSellerOffer(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSellerShow))
