import React, { Component } from "react"
import PropTypes, { array } from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { map } from "lodash"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBuilders, deleteBuilder, addBuilder, getBuilderDetail, updateBuilder } from "../../../store/builder/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import backIcon from "../../../assets/images/rsicons/backIcon.png"
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Input
} from "reactstrap"
import RsLink from "components/buttons/rsLink";


class Builder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      builder: '',
      file: null,
      searchTerm: '', 
    }
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.toggleViewDetail = this.toggleViewDetail.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }
  onSearchChange(event) {
    this.setState({ searchTerm: event.target.value });
  }
  toggleNew() {
    this.setState(prevState => ({
      isAddNew: !prevState.isAddNew
    }));
  }
  toggleDetail() {
        
    this.setState({ isViewDetail: !this.state.isViewDetail });
    
  }
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });

  }
  toggleViewDetail() {
    this.setState({ isViewDetail: !this.state.isViewDetail });

  }
  toggleViewDetail() {
    this.setState({ builder: '', isUnitData: !this.state.isUnitData });
    this.toggleDetail();
  } 
  toggleViewDetail = arg => {
    const builder = arg;
    this.setState({
      builder: {
        id:builder.id,
        builderId: builder.builderId,
        builderName:builder.builderName,
        builderLogo: builder.builderLogo,
        bAddressId: builder.bAddressId,
        bIsActive: builder.bIsActive,
        agentId: builder.agentId,
        fullAddress:builder?.bAddress?.fullAddress,
        addressLine1:builder?.bAddress?.addressLine1,
        addressLine2:builder?.bAddress?.addressLine2,
        city:builder?.bAddress?.city,
        country:builder?.bAddress?.country,
        latitude:builder?.bAddress?.latitude,
        longitude:builder?.bAddress?.longitude,
        postal:builder?.bAddress?.postal,
        province:builder?.bAddress?.province,
      },
      isEdit: true,
     
    });
   // console.log(builder);
   this.toggleDetail();
};  
  componentDidMount() {
    const { onGetAllBuilder } = this.props;
    onGetAllBuilder(localStorage.getItem('userId'));
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (builder) => {
    this.setState({ builder: builder });
    this.setState({ deleteModal: true });
  };

  handleDeleteBulder = () => {
    const { onDeleteBuilder } = this.props;
    const { builder } = this.state;
    //console.log(project);
    if (builder.id !== undefined) {
      onDeleteBuilder(builder);
      this.setState({ deleteModal: false });
    }
  };
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;

        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const modifiedAddress = `${streetNumber} ${route}`;



        setFieldValue('fullAddress', place.formatted_address);
        setFieldValue('addressLine1', modifiedAddress);
        setFieldValue('addressLine2', '');
        setFieldValue(`city`, city);
        setFieldValue(`province`, province);
        setFieldValue(`postal`, postalCode);
        setFieldValue(`country`, country);
        setFieldValue('longitude', longitude);
        setFieldValue('latitude', latitude);
      })
      .catch(error => console.error('Error', error));
  };
  async handleFileChange(event, setFieldValue) {
    const file = event.currentTarget.files[0];
    if (file) {
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-06-22T02:18:02Z&st=2024-06-21T18:18:02Z&spr=https,http&sig=Srg7JmJ7EfJQWUNadO188IgXNeCFEzSmQQmctuyjGxA%3D";
      const containerName = "images";
      const storageAccountName = "realtyspace";
      const fileName = `${Date.now()}_${file.name}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });

        //alert('File uploaded successfully!');
        if (setFieldValue) {
          const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
          setFieldValue(`builderLogo`, fileUrl);
        }
      } catch (error) {
        console.error('Error uploading file:', error);

      }
    }
  }
  handleValidSubmit(value, history) {

    this.props.addBuilder(value, this.props.history)
    this.toggleNew();

  }
  render() {
    const { builders, builderData, onUpdateBuilder } = this.props;
    const { deleteModal, searchTerm, builder, isEdit  } = this.state;
    const filteredBuilders = builders.filter(builder =>
      builder.builderName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log(builder);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteBulder}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Builder | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            {/* Render Breadcrumbs */}
            <Row className="project-header">
              <Col sm="7">
                <Breadcrumbs
                  title={this.props.t("Builder")}
                  breadcrumbItem={this.props.t("Builder")}
                />
              </Col>
              <Col sm="5 ">
              <div className="mt-2 pt-2">
              <RsLink onClick={this.toggleAddNew}  className="btn btn btn-light btn-md float-end" iconClass="bx bx-plus">Add New</RsLink>
              {/* <Link tag={Link} onClick={this.toggleAddNew} on className="btn btn-add float-end mt-2"><i className="bx bx-plus"></i> Add New</Link> */}
              </div>
              </Col>
            </Row>
            <div className="row mt-20">
          
          <div className="col-sm-4">
          <Link to={"/pre-construction/my-project" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
          
                  {/* <span><b>{this.props.t("Management")}</b></span> */}
                </Link>

          </div>
            
                      <div className="col-sm-4 mb20">
                        <Input type="text" className="form-control"  value={searchTerm}   onChange={this.onSearchChange} id="autoSizingInput" placeholder="Search By Name" />
                      </div>


                    </div>
                    <div className="clearfix"></div>
            <Row className="mt-20">
              {map(filteredBuilders, (builder, prokey) => (
                <Col sm="2" key={prokey}>
                  <div className="card">
                  <div className="card-header">
                    <h5>{builder.builderName}</h5>
                  </div>  
                    <div className="card-body p-0 text-center minHeight">
                    
                      <img src={builder.builderLogo} alt={`Image for builder ${builder.builderName}`} className="img-fluid" />
                   
                    </div>

                    <div className="bg-transparent border-top card-footer">
                        <div className="contact-links d-flex font-size-20">
                            <div className="flex-fill">
                                <Link to="#" onClick={() => this.toggleViewDetail(builder)}>
                                    Edit
                                </Link>
                            </div>
                    
                      <div className="float-end">
                      <Link to={"/pre-construction/builder/builder-detail/" + builder.id + "/"  + builder.builderId}>
                            Projects
                      </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

          </Container>
        </div>


        <Offcanvas
          isOpen={this.state.isAddNew}
          direction="end"
          toggle={this.toggleAddNew}
        >
          <OffcanvasHeader toggle={this.toggleAddNew}>
            New Builder
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                builderName: (this.state && this.state.builderName) || "",
                agentId: parseInt(localStorage.getItem('userId')),
                builderLogoImage: '',
                fulladdress: '',
                longitude: '',
                latitude: '',



              }}
              validationSchema={Yup.object().shape({
                builderName: Yup.string().required("This is Required")
              })}

              onSubmit={this.handleValidSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderName">
                          {this.props.t("Builder Name")}
                        </Label>
                        <Field
                          name="builderName"
                          onChange={handleChange}
                          type="text"
                          value={values.builderName}
                          className={
                            "form-control" +
                            (errors.builderName && touched.builderName
                              ? " is-invalid"
                              : "")
                          }
                          id="builderName"

                        />
                        <ErrorMessage
                          name="builderName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addressLine1">
                          {this.props.t("Location")}
                        </Label>
                        <PlacesAutocomplete
                          value={values.fullAddress}
                          onChange={(value) => setFieldValue('fullAddress', value)}
                          onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address...',
                                  className: 'form-control',

                                })}
                              />
                              <div className="">
                                {loading ? <div>Loading...</div> : null}

                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                    : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </FormGroup>
                    </Col>



                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderLogo">
                          {this.props.t("Upload Logo")}
                        </Label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={event => this.handleFileChange(event, setFieldValue)}
                        />



                      </FormGroup>
                    </Col>



                  </Row>

                  <Row>

                    <div className="clearFix"><button type="submit" className="btn btn-primary w-md  mt20" name="addBuilder" id="addBuilder"> {this.props.t("Save")}</button></div>
                  </Row>

                </Form>
              )}
            </Formik>

          </OffcanvasBody>
        </Offcanvas>
        <Offcanvas
          isOpen={this.state.isViewDetail}
          direction="end"
          toggle={this.toggleViewDetail}
        >
          <OffcanvasHeader toggle={this.toggleViewDetail}>
            Builder Detail
          </OffcanvasHeader>
          <OffcanvasBody>
          <Formik
              enableReinitialize={true}
              initialValues={{
                 id:builder.id,
                  builderId: builder.builderId,
                  builderName:builder.builderName,
                  builderLogo: builder.builderLogo,
                  bAddressId: builder.bAddressId,
                  bIsActive: builder.bIsActive,
                  agentId: builder.agentId,
                  fullAddress:builder?.fullAddress,
                  addressLine1:builder?.addressLine1,
                  addressLine2:builder?.addressLine2,
                  city:builder?.city,
                  country:builder?.country,
                  latitude:builder?.latitude,
                  longitude:builder?.longitude,
                  postal:builder?.postal,
                  province:builder?.province,
                  builderLogoImage:'',
                  builderLogoImageContent:''

              }}
              validationSchema={Yup.object().shape({
                builderName: Yup.string().required("This is Required")
              })}

              onSubmit={values => {
                //console.log(isEdit);
                if (isEdit) {
                  const updateData = {
                    id:values.id,
                    builderId: values.builderId,
                    builderName:values.builderName,
                    builderLogo: values.builderLogo,
                    bAddressId: values.bAddressId,
                    bIsActive: values.bIsActive,
                    agentId: values.agentId,
                    fullAddress:values.fullAddress,
                    addressLine1:values.addressLine1,
                    addressLine2:values.addressLine2,
                    city:values.city,
                    country:values.country,
                    latitude:values.latitude,
                    longitude:values.longitude,
                    postal:values.postal,
                    province:values.province,
                  };
                  onUpdateBuilder(updateData)
                } else {
                  
                }
                this.toggle();
              }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderName">
                          {this.props.t("Builder Name")}
                        </Label>
                        <Field
                          name="builderName"
                          onChange={handleChange}
                          type="text"
                          value={values.builderName}
                          className={
                            "form-control" +
                            (errors.builderName && touched.builderName
                              ? " is-invalid"
                              : "")
                          }
                          id="builderName"

                        />
                        <ErrorMessage
                          name="builderName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="fulladdress">
                          {this.props.t("Location")}
                        </Label>
                        <PlacesAutocomplete
                          value={values.fullAddress}
                          onChange={(value) => setFieldValue('fullAddress', value)}
                          onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address...',
                                  className: 'form-control',

                                })}
                              />
                              <div className="">
                                {loading ? <div>Loading...</div> : null}

                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                    : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </FormGroup>
                    </Col>



                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderLogo">
                          {this.props.t("Upload Logo")}
                        </Label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={event => this.handleFileChange(event, setFieldValue)}
                        />
                      </FormGroup>
                      {builder.builderLogo ? <img src={builder.builderLogo} /> : '' }
                    </Col>



                  </Row>

                  <Row>

                    <div className="clearFix"><button type="submit" className="btn btn-primary w-md  mt20" name="addBuilder" id="addBuilder"> {this.props.t("Update")}</button></div>
                  </Row>

                </Form>
              )}
            </Formik>
      
          </OffcanvasBody>
          </Offcanvas>
      </React.Fragment>
    )
  }


}

Builder.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onGetAllBuilder: PropTypes.func,
  builders: PropTypes.array,
  loading: PropTypes.object,
  addBuilder: PropTypes.func,
  onDeleteBuilder: PropTypes.func,
  onGetBuilderDetail:PropTypes.func,
  builderData:PropTypes.object,
  onUpdateBuilder:PropTypes.func

}
const mapStateToProps = ({ Builder }) => (
  {
    builders: Builder.builders,
    loading: Builder.loading,
    builderData:Builder.builderData
  })

const mapDispatchToProps = dispatch => ({
  onGetAllBuilder: (agentId) => dispatch(getBuilders(agentId)),
  addBuilder: (data) => dispatch(addBuilder(data)),
  onDeleteBuilder: (id) => dispatch(deleteBuilder(id)),
  onGetBuilderDetail:(id) => dispatch(getBuilderDetail(id)),
  onUpdateBuilder:(data) => dispatch(updateBuilder(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Builder)))