import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getClientDetail} from "../../store/clients/actions"

import * as Yup from "yup";
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { AvField, AvForm, AvCheckbox } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import ClientBuyerBreadcrumbs from "components/Common/ClientBuyerBreadcrumb";
import ClientBuyerMenu from "./menu/client-buyer-menu";
import NoAccess from "components/NoAccess";

const optionGroup = [
    {
      options: [
        { label: "Renting", value: "Renting" },
        { label: "Homeowner", value: "Homeowner" }
      ]
    }
  ]
  
  const sqrFts = [
    {
      options:[
        { label: "< 1000", value:"< 1000"},
        { label: "1000-1500", value:"1000-1500"},
        { label: "1501-2000", value:"1501-2000"},
        { label: "2001-2500", value:"2001-2500"},
        { label: "2501-3000", value:"2501-3000"},
        { label: "3001-3500", value:"3001-3500"},
      ]
    }
  ]
  
  const propIns = [
    {
      options:[
        { label: "Residential", value:"Residential"},
        { label: "Commercial", value:"Commercial"}
      ]
    }
  ]
  
  const propDeal = [
    {
      options:[
        { label: "1", value:"1"},
        { label: "2-3", value:"2-3"},
        { label: "3-4", value:"3-4"},
        { label: "4-5", value:"4-5"},
        { label: "5-7", value:"5-7"},
        { label: "7-10", value:"7-10"},
        { label: "+10", value:"+10"}
      ]
    }
  ]
  
  const propRisk = [
    {
      options:[
        { label: "Low Risk", value:"Low Risk"},
        { label: "Medium Risk", value:"Medium Risk"},
        { label: "High Risk", value:"High Risk"},
       
      ]
    }
  ]
class ClientHomePref extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedGroup: null,
        homeprefrences: []
    }
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  componentDidMount() {
    const { match: { params },onGetClientDetail} = this.props;
    onGetClientDetail(params.clientId);

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    // axios.get(`https://rscoreapi.azurewebsites.net/api/ClientBuyerHomePreferenceAnswer/all/?clientId=${this.props.match.params.id}`, {
      axios.get(`https://rscoreapi.azurewebsites.net/api/ClientBuyerHomePreferenceAnswer/all/?clientId=3`, {
        params: {
          forType: 3,

        }, headers: { Authorization: str }
      })
        .then(res => {
          const homeprefrences = res.data;
          this.setState({ homeprefrences });
        })
}
  render() {
    const { selectedGroup } = this.state
    const { clietDetail } = this.props;
    const hasBuyerType = clietDetail.clientTypes && clietDetail.clientTypes.some((type) => type.clientTypeCode === 1);
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          {hasBuyerType ? (
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientBuyerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Home Preferences")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientBuyerMenu />
            <Row>
            {/* <Col xl="3">
            <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
            <Col xl="12">
            <Card > 
                      <CardBody>
                        <Row>
                          <Col sm="12">
                          <h4 >Home Preferences</h4>
                          <hr/>
                        <div className="table-responsive">
                            <table className="table mb-0 table">
                            <thead>
                              <tr>
                              
                              <th>Name</th>
                              <th >Value</th>
                              <th>Preference</th>
                              {/* <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.homeprefrences
                                    .map((homeprefrence, appoint) => (
                                <tr key={"_app_" + appoint}>
                                  <td>{homeprefrence.homePreferenceTitle}</td>
                                  
                                  <td >{homeprefrence.answersOption}</td>
                                  <td>{homeprefrence.preference}</td>
                                  
                                  

                                </tr> 
                                        
                            ))}
                          
                              
                            </tbody>
                            </table>

                          
                          </div> 
                        </Col>
                      </Row>
                    </CardBody>
                    </Card>
            </Col>
            
            </Row>
            </Col>
            </Row>
            ) : (
              <NoAccess />
             
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientHomePref.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object


}

const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
   

  })

const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientHomePref))
