import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import notes from "../../assets/images/rsicons/notes.png"
import phonelog from "../../assets/images/rsicons/phone-log.png"
import emaillog from "../../assets/images/rsicons/email-log.png"
import prefrence from "../../assets/images/rsicons/prefrence.png"
import { getContactDetail,  getAllContactActivityTab,
  getCampaignEmailList,
  getCampContactList

 } from "../../store/actions"
import Moment from 'react-moment';
import "react-rangeslider/lib/index.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"

import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactEdit from "./forms/contact-edit";
import ContactDetailMenu from "./menu/detail-menu"
import ContactData from "./forms/contact-data";
import AppointmentLog from "./tab-comp/AppointmentLog";
import LeadNote from "./tab-comp/LeadNote";
import EmaillLog from "./tab-comp/EmaillLog";
import CallLog from "./tab-comp/CallLog";
import { API_URL } from "helpers/app_url";
class ContactDetail extends Component {
  constructor(props) {
    super(props)
    this.autocomplete = null,
      this.state = {
        col9: false,
        col10: false,
        col11: false,
        col12: true,
        col15: false,
        selectedGroup: null,
        activeTab: "1",
        verticalActiveTab: "1",
        selectedValue: '',
        value: 0,
        viewmodal: false,
        modal: false,
        titles:[],
        statuses : [],
    
        
      }
     
      this.t_col9 = this.t_col9.bind(this)
      this.t_col10 = this.t_col10.bind(this)
      this.t_col11 = this.t_col11.bind(this)
      this.t_col12 = this.t_col12.bind(this)
      this.t_col15 = this.t_col15.bind(this)
      this.toggle = this.toggle.bind(this);
 

  }
 
 
  componentDidMount() {
    const {match: { params }, onGetContactDetail,  onGetAllContactAct, onGetCampaignEmailList, onGetCampContactList  } = this.props;
    if (params && params.id) {
      onGetContactDetail(params.contactid);
      onGetAllContactAct(params.id);
      onGetCampaignEmailList(localStorage.getItem('userId')),
      onGetCampContactList(localStorage.getItem('userId'),params.id);
      this.setState({ Id: params.id });
    } else {
      onGetContactDetail(2);
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }
  render() {
    const { contactDetail, allactivities, campaignemaillists, camplists, onGetCampContactList } = this.props;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
   console.log(camplists);
    return (
      <React.Fragment>
        
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <ToastContainer autoClose={2000} />
            <Row>
              <Col sm='3'>
              
                <ContactData contactDetail={contactDetail}  />
              </Col>
              <Col sm='9'>
              <Row className="project-header">
                <Col sm='8'>
            <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Contact Detail")}
            />
            {/* <Link to="/all-contacts" className="mb-4 text-black" ><div className="mb-2"><img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/> {" "}Back to Contacts </div></Link> */}
            </Col>
            <Col sm='4'>
                <h2>{contactDetail.firstName + " " + contactDetail.lastName }</h2>
            </Col>
            </Row>
            <ContactDetailMenu />
            <Row>
            <Col xl="12">
                             <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10"
                                  >
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "1",
                                  })}
                                  onClick={() => {
                                    this.toggle("1");
                                    this.props.onGetAllContactAct(this.state.Id);
                                  }}
                                >
                                  <span className="">{this.props.t("All Activities")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "2",
                                  })}
                                  onClick={() => {
                                    this.toggle("2")
                                  }}
                                >

                                  <span className="">{this.props.t("Meetings")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "3",
                                  })}
                                  onClick={() => {
                                    this.toggle("3")
                                  }}
                                >

                                  <span className="">{this.props.t("Notes")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "6",
                                  })}
                                  onClick={() => {
                                    this.toggle("6")
                                  }}
                                >

                                  <span className="">{this.props.t("Email Logs")}</span>
                                </NavLink>
                              </NavItem>


                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "5",
                                  })}
                                  onClick={() => {
                                    this.toggle("5")
                                  }}
                                >

                                  <span className="k">{this.props.t("Call Logs")}</span>
                                </NavLink>
                              </NavItem>


                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "7",
                                  })}
                                  onClick={() => {
                                    this.toggle("7")
                                  }}
                                >

                                  <span className="">{this.props.t("Referrals")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "8",
                                  })}
                                  onClick={() => {
                                    this.toggle("8")
                                  }}
                                >

                                  <span className="">{this.props.t("Transactions")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "9",
                                  })}
                                  onClick={() => {
                                    this.toggle("9")
                                  }}
                                >

                                  <span className="">{this.props.t("Interested In")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "11",
                                  })}
                                  onClick={() => {
                                    this.toggle("11")
                                  }}
                                >

                                  <span className="">{this.props.t("Collections")}</span>
                                </NavLink>
                              </NavItem>    


                            </Nav>
                            <Card>
                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                              <TabPane tabId="1">
                                <div className="mb-5 h4 card-title">All Activities</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(allactivities, (allactivity, allactkey) => (

                                    <li className="event-list eventlist" key={"_allact_" + allactkey}>
                                      <div className="event-timeline-dot">
                                       {(() => {
                                                  if(allactivity.activitySubType==8 || allactivity.activitySubType==9 || allactivity.activitySubType==7){
                                                    return (
                                                      <>
                                                       <img src={emaillog} />
                                                      </>
                                                    )
                                                  }else if(allactivity.activitySubType==3 || allactivity.activitySubType==4 || allactivity.activitySubType==5){
                                                    return (
                                                      <>
                                                       <img src={phonelog} />
                                                      </>
                                                    )
                                                  }else{
                                                    return (
                                                      <>
                                                       <img src={notes} />
                                                      </>
                                                    )

                                                  }
                                            })()}
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{allactivity.addedDate}</Moment>

                                            
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ allactivity.activityType+"_"+allactivity.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{allactivity.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>
                              <TabPane tabId="2">
                                  <AppointmentLog contactDetail={contactDetail} />
                              </TabPane>
                              <TabPane tabId="3">
                                  <LeadNote contactDetail={contactDetail} />
                                  
                             
                              </TabPane>
                              <TabPane tabId="6">
                               <EmaillLog contactDetail={contactDetail} />
                 
                              
                              </TabPane>      

                              <TabPane tabId="5">
                                  <CallLog contactDetail={contactDetail} />
                              </TabPane>

                             
                              

                              <TabPane tabId="7">
                                <div className="mb-5 h4 card-title">Referrals</div>
                                <Table>
                                  <thead>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Creation Date</th>
                                    <th>Current Status</th>
                                    <th>Transactions</th>
                                  </thead>

                                </Table>

                              </TabPane>

                                
                              <TabPane tabId="11">
                        <div className="mb-5 h4 card-title">
                          Collection
                        </div>
                        <div className="table-responsive">
                        <Formik
                          initialValues={{
                         
                            agentId: parseInt(localStorage.getItem('userId')),
                            campaignEmailListId:0, 
                            firstName: contactDetail.firstName,
                            lastName: contactDetail.lastName,
                            emailAddress: contactDetail.email,
                            contactNumber: contactDetail.mobile,
                            clientId:0,
                            leadId:0,
                            contactId:parseInt(contactDetail.id),
                            isUnsubscribed:false,
                            isActive:true,
                            checkedItems:[]
                            
                          }}
                          validationSchema={Yup.object().shape({
                            campaignEmailListId: Yup.array().min(1, 'Please select at least one collection')
                          })}
                          //onSubmit={this.handleSubmit}
                        >
                        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                          <Form className="needs-validation">
                          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th className="align-middle">
                                  <div className="form-check font-size-16 align-middle"></div>
                                </th>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                            {campaignemaillists?.map((campaignemaillist, index) => {
  
                            const isChecked = () => {
                              return camplists?.some(
                                (item) =>
                                  item.campaignEmailListId === campaignemaillist.id &&
                                  !item.isUnsubscribed &&
                                  item.contactId === contactDetail.id
                              );
                              };


                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check font-size-16 align-middle">
                                        <input
                                          name={`campaignEmailListItem_${campaignemaillist.id}`}
                                          type="checkbox"
                                          checked={isChecked()} 
                                          onChange={() => {
                                            const obj = JSON.parse(localStorage.getItem("authUser"));
                                            const token = obj?.resultData?.bearerToken;
                                            const str = `Bearer ${token}`;
                                            const currentlyChecked = isChecked();
                                            console.log(currentlyChecked);
                                            const camplistItem = camplists?.find(
                                              (item) =>
                                                item.campaignEmailListId === campaignemaillist.id &&
                                                item.contactId === contactDetail.id
                                            );
                                            const id = camplistItem ? camplistItem.id : 0;
                                            const campaignEmailListItemId = camplistItem ? camplistItem.campaignEmailListItemId : "3fa85f64-5717-4562-b3fc-2c963f66afa6"; 
                                            const requestPayload = {
                                              id,
                                              campaignEmailListItemId, 
                                              campaignEmailListId: campaignemaillist.id, 
                                              agentId: parseInt(values.agentId),
                                              emailAddress: contactDetail.email,
                                              firstName: contactDetail.firstName,
                                              lastName: contactDetail.lastName,
                                              contactNumber: contactDetail.mobile,
                                              clientId:0,
                                              contactId:parseInt(contactDetail.id),
                                              leadId: 0,
                                              isUnsubscribed: currentlyChecked,
                                              isActive: true,
                                            };
                                            const apiUrl = `${API_URL}CampaignEmailListItem`; 
                                            const method = camplistItem  ? "put" : "post";
                                            axios({
                                              method,
                                              url: apiUrl,
                                              data: requestPayload,
                                              headers: {
                                                "Content-Type": "application/json",
                                                Authorization: str,
                                              },
                                            })
                                              .then((response) => {
                                                console.log('API response:', response);
                                                onGetCampContactList(localStorage.getItem('userId'),contactDetail.id)
                                              })
                                              .catch((error) => {
                                                console.error('Error during API call:', error);
                                              });
                                          }}
                                          className="form-check-input"
                                          id={`campaignEmailListItem_${campaignemaillist.id}`}
                                        />
                                        <label
                                          htmlFor={`campaign_${campaignemaillist.id}`}
                                          className="form-check-label"
                                        >
                                          &nbsp;
                                        </label>
                                        {touched.campaignEmailListId && errors.campaignEmailListId ? (
                                          <div className="invalid-feedback d-block">
                                            {errors.campaignEmailListId}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                    <td>{campaignemaillist.campaignEmailListName}</td>
                                    <td>{campaignemaillist.campaignEmailListDesc}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                    
                          {touched.campaignEmailListId && errors.campaignEmailListId ? (
                            <div className="text-danger mt-2">{errors.campaignEmailListId}</div>
                          ) : null}
                          {/* <SaveButton>Submit</SaveButton> */}
                        </Form>
                         
                        )}
                      </Formik>
                        </div>
                      </TabPane>


                            </TabContent>
                            </Card>
            </Col>
            </Row>
             
              </Col>
            </Row>
          
           
          
          </Container>
        </div>

        <Offcanvas
                      isOpen={this.state.isRightEdit}
                      direction="end"
                      toggle={this.toggleRightCanvasEdit}
                      style={{width:800}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvasEdit}>
                        Update Contact
                      </OffcanvasHeader>
                      <OffcanvasBody>
                        <ContactEdit />

                      </OffcanvasBody>
                      </Offcanvas>
    
      </React.Fragment>
    )
  }
}
ContactDetail.propTypes = {
  error: PropTypes.any,
  t: PropTypes.any,
  match: PropTypes.object,
  onGetContactDetail: PropTypes.object,
  history: PropTypes.object,
  onGetAllContactAct:PropTypes.func,
  contactDetail: PropTypes.object,
  allactivities:PropTypes.array,
  campaignemaillists:PropTypes.array,
  onGetCampaignEmailList:PropTypes.func,
  onGetCampContactList:PropTypes.func,
  camplists:PropTypes.array

}

const mapStateToProps = ({ AppoinmentObject, contact }) => (
  //console.log(contact),
  {
    contactDetail: contact.contactDetail,
    appDetails:contact.appDetails,
    campaignemaillists:AppoinmentObject.campaignemaillists,
    camplists:contact.camplists
    
  })

const mapDispatchToProps = dispatch => ({
  onGetContactDetail: id => dispatch(getContactDetail(id)),
  onGetAllContactAct: id => dispatch(getAllContactActivityTab(id)),
  onGetCampaignEmailList:(agentId) => dispatch(getCampaignEmailList(agentId)),
  onGetCampContactList:(agentId, contactId) => dispatch(getCampContactList(agentId, contactId)),

});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactDetail))
