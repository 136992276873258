import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import Flatpickr from "react-flatpickr"
import { getClientDetail} from "../../store/clients/actions"


import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm, AvCheckbox } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import ClientInvestorBreadcrumbs from "components/Common/ClientInvestorBreadcrumb";
import ClientInvestorMenu from "./menu/client-investor-menu";
import NoAccess from "components/NoAccess";

class ClientInvPref extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedGroup: null,
        investorQuestions: []
    }
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  toggleRightCanvas2 = arg => {
    const showing = arg;
  
    this.setState({
      showing: {
    
        id:1,
    
      },
      isEdit: true,
    });
    this.toggle2();
    console.log("sadasd")
  };

  toggleRightCanvas2() {
    this.setState({ showing: "", isEdit: false, isRight2: !this.state.isRight2 });
    this.toggle2();
    //console.log("asda")
  }

  toggle2() {
    this.setState(prevState => ({
      isRight2: !prevState.isRight2
    }));
  }


  componentDidMount() {
    const { match: { params }, onGetClientDetail} = this.props;
    onGetClientDetail(params.clientId);
   
}
  render() {
    const { clietDetail } = this.props;
    const { selectedGroup } = this.state
    const hasInvestorType = clietDetail.clientTypes && clietDetail.clientTypes.some((type) => type.clientTypeCode === 4);
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          {hasInvestorType ? (
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientInvestorBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( " Investment Projects")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientInvestorMenu />
            <Row>
            {/* <Col xl="3">
            <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
            <Col xl="12">
            <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Investment Projects</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								
								<th>Project Name</th>
								<th>Unit#</th>
								<th>Price</th>
								<th>Type</th>
                <th>Date</th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
                <tr>
                  <td>Libarty Tower</td>
                  <td>601</td>
                  <td>$630,000</td>
                  <td>1 Br + D</td>
                  <td>15th April, 2024</td>
                  <td onClick={() => this.toggleRightCanvas2()}><Link >Edit</Link></td>
                </tr>

                <tr>
                  <td>Crown Tower</td>
                  <td>2206</td>
                  <td>$570,000</td>
                  <td>1 Br</td>
                  <td>30th April, 2024</td>
                  <td onClick={() => this.toggleRightCanvas2()}><Link >Edit</Link></td>
                </tr>
             
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>


            </Col>
            
            </Row>
            </Col>
            </Row>
            ) : (
              <NoAccess />
            )}
            <Offcanvas
                      isOpen={this.state.isRight2}
                      direction="end"
                      toggle={this.toggleRightCanvas2}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                    enableReinitialize={true}
                    initialValues={{

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required("Please Enter Meeting Agenda"),
                      appointmentType : Yup.number().required("This field is required"),
                      appointmentPriority   :  Yup.number().required("This field is required"),
                      appointmentLocationType  :  Yup.number().required("This field is required"),
                    })}

                    onSubmit={values => {
                      //console.log(isEdit);
                      if (isEdit) {
                        const updateApp = {
                          id:values.id,
                          appointmentId: values.appointmentId,
                          appointmentTitle:values.appointmentTitle,
                          contactName: values.contactName,
                          contactPhone: values.contactPhone,
                          contactEmail: values.contactEmail,
                          appointmentStartDate: values.appointmentStartDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          appointmentType: (values.appointmentType) ? parseInt(values.appointmentType) : 0,
                          appointmentStatus: (values.appointmentStatus) ? parseInt(values.appointmentStatus) : 0,
                          appointmentPriority: (values.appointmentPriority) ? parseInt(values.appointmentPriority) : 0,
                          appointmentReminder: values.appointmentReminder,
                          clientId: values.clientId,
                          appointmentDescription:values.appointmentDescription,
                          appointmentLocationType:values.appointmentLocationType,
                          appointmentLocation:values.appointmentLocation,
                          locationLatitude:values.locationLatitude,
                          locationLongitude:values.locationLongitude,
                          isRepeat:values.isRepeat,
                          repeatEveryType:values.repeatEveryType,
                          repeatStep:values.repeatStep,
                          repeatEndDate:values.repeatEndDate,
                          agentId:values.agentId,
                          isActive:values.isActive,
                          agentNotes: values.agentNotes,
                          addedBy: values.addedBy
                        };
  
                        onUpdateClientAppointment(updateApp);
                      } else {
                        
                      }
                      this.toggle();
                    }}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>

           

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="project_name">
                                {this.props.t("Project Name")}
                              </Label>
                              <input
                                name="project_name"
                                type="text"
                                value={values.project_name}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.project_name && touched.project_name
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="project_name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="unit">
                                {this.props.t("Unit#")}
                              </Label>
                              <input
                                name="unit"
                                type="text"
                                value={values.unit}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.unit && touched.unit
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="unit"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          
                    
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="price">
                                {this.props.t("Price")}
                              </Label>
                              <input
                                name="price"
                                type="text"
                                value={values.price}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.price && touched.price
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="price"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="type">
                                {this.props.t("Type")}
                              </Label>
                              <input
                                name="type"
                                type="text"
                                value={values.type}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.type && touched.type
                                    ? " is-invalid"
                                    : "")
                                }
                                id="type"
                              />
                              <ErrorMessage
                                name="type"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          


                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="date">
                                {this.props.t("Date*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.date && touched.date
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.date}
                                onChange={(value) => setFieldValue('date', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  defaultDate:'today'
                                }}
                              />
                              <ErrorMessage
                                name="date"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>




                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>
                      </Form>
                    )}
                  </Formik>
                      </OffcanvasBody>
                      </Offcanvas>   

                      
         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientInvPref.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object

}

const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
   

  })

const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientInvPref))
