import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"
import { forgetPasswordApiLink } from "helpers/backend_helper";

function* forgetUser({ payload: { user, history } }) {
  try {
    toast.loading("Please wait...")
      const response = yield call(forgetPasswordApiLink, user)
      if (response) {
        toast.dismiss();
        toast.success("Reset link are sended to your mailbox");
        yield put(
          userForgetPasswordSuccess("Reset link are sended to your mailbox, check there first")
        )
      }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export default forgetPasswordSaga
