import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import "react-rangeslider/lib/index.css";
import notes from "../../assets/images/rsicons/notes.png";
import follow from "../../assets/images/rsicons/follow-up.png";
import phonelog from "../../assets/images/rsicons/phone-log.png";
import emaillog from "../../assets/images/rsicons/email-log.png";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CallLog from "./tab-comp/CallLog";
import EmaillLog from "./tab-comp/EmaillLog";
import SmsLog from "./tab-comp/SmsLog";
import AppointmentLog from "./tab-comp/AppointmentLog";
import LeadNote from "./tab-comp/LeadNote";
import FollowLead from "./tab-comp/FollowLead";
import LeadListing from "./tab-comp/Lead-listing";

import EditLead from "./forms/lead-edit";
import { Container,Row,Col, Card, CardBody, Table, Collapse, FormGroup, Label, Button, ButtonGroup, Nav, NavItem, NavLink,
  TabContent, TabPane, InputGroup, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios'
import Moment from "react-moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLeadDetail, getAllActLeadTab,
  convertLeadIntoClient,
  convertLeadIntoContact,
  getLeadProject,
  getCampaignEmailList,
  getCampLeadList
} from "../../store/actions";
import RsLink from "components/buttons/rsLink";
import LeadDetailMenu from "./menu/lead-detail-menu";
import EmailLeadInbox from "pages/Email/email-inbox-lead";
import LeadDetailLeft from "./forms/lead-detail-left";
import SaveButton from "components/buttons/save";
import { API_URL } from "helpers/app_url";

class leadDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      open: false,
      isRight: false,
      isRight2: false,
      isRight3: false,
      isBackdrop: false,
      col9: false,
      col15: false,
      col10: false,
      col11: false,
      col12: true,
      selectedGroup: null,
      activeTab: "1",
      verticalActiveTab: "1",
      volume: 0,
      leadData: [],
      buyersQuestions: [],
      sources: [],
      leadStatus: [],
      leadTypes: [],
      groupDrops: [],
      maritalStat: [],
      prefMediums: [],
      likelihoods: [],
      titles: [],
      total: 0,
      numbers: {
        comp1: 0,
        comp2: 0,
        comp3: 0,
        comp4: 0,
        comp5: 0,
      },
      leadId: "",
      Id: "",
      ccTab:"1"
    };
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
    this.toggleRightCanvas3 = this.toggleRightCanvas3.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);
    this.t_col12 = this.t_col12.bind(this);
    this.t_col15 = this.t_col15.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleRightCanvas2() {
    this.setState({ isRight2: !this.state.isRight2 });
  }

  toggleRightCanvas3() {
    this.setState({ isRight3: !this.state.isRight3 });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  handleOnChange = value => {
    this.setState({
      volume: value,
    });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  cctoggle(tab) {
    if (this.state.ccTab !== tab) {
      this.setState({
        ccTab: tab,
      });
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false,
    });
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false,
    });
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false,
    });
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false,
    });
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false,
    });
  }
  componentDidMount() {
    const {
      match: { params },
      onGetLeadDetail,
      onGetAllActLead,
      onGetLeadProject,
      onGetCampaignEmailList,
      onGetCampLeadList
    } = this.props;
    if (params && params.id && params.leadId) {
      onGetLeadDetail(params.leadId);
      onGetAllActLead(params.id);
      onGetLeadProject(params.id)
      onGetCampaignEmailList(localStorage.getItem('userId')),
      onGetCampLeadList(localStorage.getItem('userId'),params.id);
      this.setState({ leadId: params.leadId });
      this.setState({ Id: params.id });
    }
   
  }
  handleSubmit = (values, { setSubmitting }) => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str =  `Bearer ${token}`;
    toast.loading("Please wait...")
       const formattedData = {
        agentId: parseInt(values.agentId),
        campaignEmailListId: parseInt(campaignId),
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        contactNumber: values.contactNumber,
        clientId: 0, 
        leadId: parseInt(values.leadId), 
        contactId: 0, 
        isUnsubscribed: false,
        isActive: true
    };
    console.log(formattedData);
    axios.post(`${API_URL}CampaignEmailListItem`, formattedData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': str,
      },
    })
        .then(response => response.json())
        .then(data => {
          toast.dismiss();
          if (data.success) {
              toast.success("Lead added into collection successfully!");
          } else {
              toast.error("Failed to add lead into  collection. Please try again.");
          }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Error occurred while updating. Please try again.");
      })
      .finally(() => {
        toast.dismiss();
        setSubmitting(false); 
      });
  };

  render() {
    const {leads, onConvertLeadToClient,onCovertLeadIntoContact, projects, campaignemaillists, camplists, onGetCampLeadList} = this.props;
    const { activities } = this.props;
    const allLocal = localStorage.getItem("localData");
    const localization = JSON.parse(allLocal);
    const userModule = localStorage.getItem("userModule");
    const leadQualificationPoints = leads?.leadQualificationPoints;
    const formattedPoints = leadQualificationPoints?.toFixed(2);
    const seriesData = [formattedPoints];
    console.log(camplists);
    return (
      
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm="3" className="pe-0">
                <LeadDetailLeft />
              </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
            <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Detail")}
            />
           
            </Col>
            <Col sm='4'>
                <h2>{leads.firstName + " " + leads.lastName }</h2>
            </Col>
            </Row>
                 <LeadDetailMenu />     
                <Row>
                  <Col xl="12">
                  <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10"
                                  >
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "1",
                                        })}
                                        onClick={() => {
                                          this.toggle("1");
                                          this.props.onGetAllActLead(this.state.Id);
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("All ")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "12",
                                        })}
                                        onClick={() => {
                                          this.toggle("12");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Inbox")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "2",
                                        })}
                                        onClick={() => {
                                          this.toggle("2");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Appointments")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "3",
                                        })}
                                        onClick={() => {
                                          this.toggle("3");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Notes")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "4",
                                        })}
                                        onClick={() => {
                                          this.toggle("4");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Follow-ups")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "5",
                                        })}
                                        onClick={() => {
                                          this.toggle("5");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Logs")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                  

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "8",
                                        })}
                                        onClick={() => {
                                          this.toggle("8");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Listings")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "10",
                                        })}
                                        onClick={() => {
                                          this.toggle("10");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Marketing")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "11",
                                        })}
                                        onClick={() => {
                                          this.toggle("11");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Collections")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "9",
                                        })}
                                        onClick={() => {
                                          this.toggle("9");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Projects")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                    </Nav>
                    <Card>
                    
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <div className="mb-5 h4 card-title">
                          All Activities
                        </div>
                        <ul className="verti-timeline list-unstyled">
                          {map(
                            activities,
                            (allactivity, allactkey) => (
                              <li
                                className="event-list eventlist"
                                key={"_allact_" + allactkey}
                              >
                                <div className="event-timeline-dot">
                                  {(() => {
                                    if (
                                      allactivity.activitySubType ==
                                        8 ||
                                      allactivity.activitySubType ==
                                        9 ||
                                      allactivity.activitySubType ==
                                        7
                                    ) {
                                      return (
                                        <>
                                          <img src={emaillog} />
                                        </>
                                      );
                                    } else if (
                                      allactivity.activitySubType ==
                                        3 ||
                                      allactivity.activitySubType ==
                                        4 ||
                                      allactivity.activitySubType ==
                                        5
                                    ) {
                                      return (
                                        <>
                                          <img src={phonelog} />
                                        </>
                                      );
                                    } else if (
                                      allactivity.activitySubType ==
                                      10
                                    ) {
                                      return (
                                        <>
                                          <img src={follow} />
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <img src={notes} />
                                        </>
                                      );
                                    }
                                  })()}
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <h5 className="font-size-14">
                                      <Moment format="D MMM YY">
                                        {allactivity.addedDate}
                                      </Moment>
                                    </h5>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14">
                                      {
                                        localization[
                                          "ActivitySubType_" +
                                            allactivity.activityType +
                                            "_" +
                                            allactivity.activitySubType
                                        ]
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="exNote">
                                  {allactivity.activityRemarks}
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </TabPane>
                      <TabPane tabId="12">
                          <EmailLeadInbox leadEmail={leads.email} />
                      </TabPane>
                      <TabPane tabId="2">
                        <AppointmentLog  />
                      </TabPane>
                      <TabPane tabId="3">
                        <LeadNote />
                      </TabPane>

                      <TabPane tabId="4">
                        <FollowLead />
                      </TabPane>
                      <TabPane tabId="5">
                      <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10 text-center"
                                  >
                                    

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "1",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("1");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Call Log")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "2",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("2");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Email Log")}
                                        </span>
                                      </NavLink>
                                    </NavItem>      
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "3",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("3");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("SMS Log")}
                                        </span>
                                      </NavLink>
                                    </NavItem>      

                                  
                    </Nav>
                    <TabContent
                      activeTab={this.state.ccTab}
                      className="p-3 text-muted"
                    >
                       <TabPane tabId="1">
                       <CallLog />
                      </TabPane>
                      <TabPane tabId="2">
                      <EmaillLog />
                      </TabPane>
                      <TabPane tabId="3">
                      <SmsLog />
                      </TabPane>
                    </TabContent>
                       
                       
                       
                      </TabPane>
                      <TabPane tabId="6">
                       
                      </TabPane>

                      <TabPane tabId="7">
                        
                      </TabPane>

                      <TabPane tabId="8">
                        <LeadListing />
                      </TabPane>

                      <TabPane tabId="9">
                        <div className="mb-5 h4 card-title">
                          Projects
                        </div>
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <Thead>
                                <Tr>
                                  <Th>{this.props.t("Date")}</Th>
                                  <Th>
                                    {this.props.t("Project")}
                                  </Th>
                                  <Th>
                                    {this.props.t("Source")}
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                              {map(projects, (project, prokey) => (
                                <Tr key={prokey}>
                                  <Th> <Moment format="D MMM YY">{project.interestedProjectDate}</Moment></Th>
                                  <Th>
                                    <span className="co-name">
                                      {" "}
                                     {project?.projectInfo?.projectName}
                                    </span>
                                  </Th>
                                  <Th></Th>
                                </Tr>
                              ))}
                              </Tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="10">
                        <div className="mb-5 h4 card-title">
                          Marketing
                        </div>
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <Thead>
                                <Tr>
                                  <Th>{this.props.t("Type")}</Th>
                                  <Th>{this.props.t("Name")}</Th>
                                  <Th>{this.props.t("Date")}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Th>Newsletter</Th>
                                  <Th>
                                    <span className="co-name">
                                      {" "}
                                      Toronto News
                                    </span>
                                  </Th>
                                  <Th>5th August, 2023</Th>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="11">
                        <div className="mb-5 h4 card-title">
                          Collection
                        </div>
                        <div className="table-responsive">
                        <Formik
                          initialValues={{
                         
                            agentId: parseInt(localStorage.getItem('userId')),
                            campaignEmailListId:0, 
                            firstName: leads.firstName,
                            lastName: leads.lastName,
                            emailAddress: leads.email,
                            contactNumber: leads.mobile,
                            clientId:0,
                            leadId:parseInt(leads.id),
                            contactId:0,
                            isUnsubscribed:false,
                            isActive:true,
                            checkedItems:[]
                            
                          }}
                          validationSchema={Yup.object().shape({
                            campaignEmailListId: Yup.array().min(1, 'Please select at least one collection')
                          })}
                          //onSubmit={this.handleSubmit}
                        >
                        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                          <Form className="needs-validation">
                          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th className="align-middle">
                                  <div className="form-check font-size-16 align-middle"></div>
                                </th>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                            {campaignemaillists?.map((campaignemaillist, index) => {
  
                            const isChecked = () => {
                              return camplists?.some(
                                (item) =>
                                  item.campaignEmailListId === campaignemaillist.id &&
                                  !item.isUnsubscribed &&
                                  item.leadId === leads.id
                              );
                              };

                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check font-size-16 align-middle">
                                        <input
                                          name={`campaignEmailListItem_${campaignemaillist.id}`}
                                          type="checkbox"
                                          checked={isChecked()} 
                                          onChange={() => {
                                            const obj = JSON.parse(localStorage.getItem("authUser"));
                                            const token = obj?.resultData?.bearerToken;
                                            const str = `Bearer ${token}`;
                                            const currentlyChecked = isChecked();
                                            console.log(currentlyChecked);
                                            const camplistItem = camplists?.find(
                                              (item) =>
                                                item.campaignEmailListId === campaignemaillist.id &&
                                                item.leadId === leads.id
                                            );
                                            const id = camplistItem ? camplistItem.id : 0;
                                            const campaignEmailListItemId = camplistItem ? camplistItem.campaignEmailListItemId : "3fa85f64-5717-4562-b3fc-2c963f66afa6"; 
                                            const requestPayload = {
                                              id,
                                              campaignEmailListItemId, 
                                              campaignEmailListId: campaignemaillist.id, 
                                              agentId: parseInt(values.agentId),
                                              emailAddress: leads.email,
                                              firstName: leads.firstName,
                                              lastName: leads.lastName,
                                              contactNumber: leads.mobile,
                                              clientId:0,
                                              contactId:0,
                                              leadId: parseInt(leads.id),
                                              isUnsubscribed: currentlyChecked,
                                              isActive: true,
                                            };
                                            const apiUrl = `${API_URL}CampaignEmailListItem`; 
                                            const method = camplistItem  ? "put" : "post";
                                            axios({
                                              method,
                                              url: apiUrl,
                                              data: requestPayload,
                                              headers: {
                                                "Content-Type": "application/json",
                                                Authorization: str,
                                              },
                                            })
                                              .then((response) => {
                                                console.log('API response:', response);
                                                onGetCampLeadList(localStorage.getItem('userId'),leads.id)
                                              })
                                              .catch((error) => {
                                                console.error('Error during API call:', error);
                                              });
                                          }}
                                          className="form-check-input"
                                          id={`campaignEmailListItem_${campaignemaillist.id}`}
                                        />
                                        <label
                                          htmlFor={`campaign_${campaignemaillist.id}`}
                                          className="form-check-label"
                                        >
                                          &nbsp;
                                        </label>
                                        {touched.campaignEmailListId && errors.campaignEmailListId ? (
                                          <div className="invalid-feedback d-block">
                                            {errors.campaignEmailListId}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                    <td>{campaignemaillist.campaignEmailListName}</td>
                                    <td>{campaignemaillist.campaignEmailListDesc}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                    
                          {touched.campaignEmailListId && errors.campaignEmailListId ? (
                            <div className="text-danger mt-2">{errors.campaignEmailListId}</div>
                          ) : null}
                          {/* <SaveButton>Submit</SaveButton> */}
                        </Form>
                         
                        )}
                      </Formik>
                        </div>
                      </TabPane>
                    </TabContent>
                     
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
leadDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object,
  onGetAllActLead: PropTypes.func,
  activities: PropTypes.array,
  onConvertLeadToClient: PropTypes.func,
  onCovertLeadIntoContact: PropTypes.func,
  onGetLeadProject:PropTypes.func,
  projects:PropTypes.array,
  campaignemaillists:PropTypes.array,
  onGetCampaignEmailList:PropTypes.func,
  onGetCampLeadList:PropTypes.func,
  camplists:PropTypes.array
};
const mapStateToProps = ({ AppoinmentObject, leads }) =>
  ({
    leads: leads.leads,
    activities: leads.activities,
    projects:leads.projects,
    campaignemaillists:AppoinmentObject.campaignemaillists,
    camplists:leads.camplists
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  onGetAllActLead: leadId => dispatch(getAllActLeadTab(leadId)),
  onConvertLeadToClient: data => dispatch(convertLeadIntoClient(data)),
  onCovertLeadIntoContact: data => dispatch(convertLeadIntoContact(data)),
  onGetLeadProject: leadId => dispatch(getLeadProject(leadId)),
  onGetCampaignEmailList:(agentId) => dispatch(getCampaignEmailList(agentId)),
  onGetCampLeadList:(agentId, LeadId) => dispatch(getCampLeadList(agentId, LeadId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(leadDetail));
