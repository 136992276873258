import axios from "axios";
import authHeader from "./jwt-token-access/auth-token-header";

const API_URL = "https://rscoreapi.azurewebsites.net/api/";
//const API_URL = "https://localhost:44325/api/";

//console.log(authHeader().Authorization);

const refreshToken = localStorage.getItem("refreshToken") || '';
const userName = localStorage.getItem("userName") || '';

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

async function refreshAccessToken() {
  try {
    const response = await axios.post(`${API_URL}user/refresh`, {
      userName: userName,
      refreshToken: refreshToken,
      accessToken: authHeader().Authorization.replace("Bearer ", ""),
    });
    
    const newToken = response.data.bearerToken;
    const expiresIn = response.data.accessTokenExpiration || 60000;
    //const expiresIn = 600;

    localStorage.setItem("token", JSON.stringify({ bearerToken: newToken }));
    localStorage.setItem("tokenExpiry", new Date().getTime() + expiresIn * 1000);
    axiosApi.defaults.headers['Authorization'] = `Bearer ${newToken}`;
    
    console.log("Token refreshed successfully");
    return newToken;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    throw error;
  }
}

axiosApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axiosApi(originalRequest); // Retry the original request
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        if (refreshError.response && refreshError.response.status === 401) {
          window.location.href = "/login"; // Redirect to login if refresh token fails
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

function scheduleTokenRefresh() {
  const expiryTime = localStorage.getItem("tokenExpiry");
  const currentTime = new Date().getTime();
  
  const refreshInMs = expiryTime ? Math.max(expiryTime - currentTime - 50000, 0) : 60 * 60 * 1000; 
  
  //console.log(`Scheduling token refresh in ${refreshInMs / 1000} seconds`);

  setTimeout(async () => {
    try {
      await refreshAccessToken(); // Refresh the token
      scheduleTokenRefresh(); // Schedule the next refresh
    } catch (error) {
      console.error("Automatic token refresh failed:", error);
    }
  }, refreshInMs);
}

// Call this function after user login or token refresh
if (localStorage.getItem("authUser")) {
  scheduleTokenRefresh();
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, {
    headers: { ...authHeader() },
    ...config
  }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, {
      headers: { ...authHeader() },
      ...config
    })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, {
      headers: { ...authHeader() },
      ...config
    })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, {
      headers: { ...authHeader() },
      ...config
    })
    .then(response => response.data);
}
