import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import { getProjectStatus as onGetProjectStatus } from 'store/actions';

const ProjectStatusOption = () => {
    const dispatch = useDispatch();
    const { docstatuses } = useSelector(state => ({
      docstatuses: state.AppoinmentObject.docstatuses,
      }));
      useEffect(() => {
        dispatch(onGetProjectStatus(localStorage.getItem("userId")));
    }, [dispatch, onGetProjectStatus]);
  
    return (
      <FormGroup className="mb-3">
        <Label htmlFor="projectStatus">Status</Label>
        <Field as="select" name="projectStatus" className="form-control">
          <option value="">Select</option>
          {docstatuses.map((option) => (
            <option key={docstatuses.id} value={option.id}>
              {option.projectStatusTitle}
            </option>
          ))}
        </Field>
        <ErrorMessage name="projectStatus" component="div" className="invalid-feedback" />
      </FormGroup>
    );
  };  

export default ProjectStatusOption;
