import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";

import axios from "axios"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getClientDetail, addClientSellerPref } from "../../store/clients/actions"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  
} from "reactstrap"

import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import SaveButton from "components/buttons/save";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerMenu from "./menu/client-seller-menu";
import { API_URL } from "helpers/app_url";
import NoAccess from "components/NoAccess";
class ClientSalePref extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buyersQuestions:[],
      sellerData: []
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  handleValidSubmit(event, values) {

    this.props.addClientSellerPref(values, this.props.history)
    
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail } = this.props;
    onGetClientDetail(params.clientId);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str =  `Bearer ${token}`;
    const authHeader = { Authorization: `Bearer ${token}` };
      axios.get( API_URL + `QuestionAnswer/allforagenttype`, {
        params: {
          forType: 2,

        }, headers: { Authorization: str }
      })
      .then(res => {
        const buyersQuestions = res.data;
        this.setState({ buyersQuestions });
      });
      axios.get( API_URL + `ClientQuestionAnswer/allfortype`, {
        params: {
          ClientId: this.props.match.params.id,
          ClientId:2,
        }, headers: { Authorization: str }
      })
        .then(res => {
          const buyerAnswers = res.data;
          this.setState({ buyerAnswers });
        });
  }
  handleSubmit(values) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str =  `Bearer ${token}`;
    toast.loading("Please wait...")
    fetch(`${API_URL}ClientQuestionAnswer/bulkadd`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': str,
      },
      body: JSON.stringify(values)
    })
    .then(response => response.json())
    .then(data => {
      toast.dismiss();
      if (data.success) {
          toast.success("Questions submitted successfully!");
      } else {
          toast.error("Failed to submit questions. Please try again.");
      }
  })
  .catch(error => {
      toast.dismiss();
      toast.error("Error occurred while submitting. Please try again.");
        console.error('Error:', error);
  });
  }

  handleUpdate(values) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str =  `Bearer ${token}`;
    toast.loading("Please wait...")
    fetch(`${API_URL}ClientQuestionAnswer/bulkadd`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': str,
      },
      body: JSON.stringify(values)
    })
    .then(response => response.json())
    .then(data => {
      toast.dismiss();
      if (data.success) {
          toast.success("Questions updated successfully!");
      } else {
          toast.error("Failed to update questions. Please try again.");
      }
  })
  .catch(error => {
      toast.dismiss();
      toast.error("Error occurred while updating. Please try again.");
        console.error('Error:', error);
  });
  }
 
  render() {
    const { clietDetail } = this.props;
    const hasSellerType = clietDetail.clientTypes && clietDetail.clientTypes.some((type) => type.clientTypeCode === 2);
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
         <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          {hasSellerType ? (
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} clientDetail={clietDetail} />
              </Col>
              <Col sm='10' className="">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(" Preference")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
             

<Card>
<Formik
                enableReinitialize={true}
                initialValues={{

                  questionAnswers: this.state.buyersQuestions.reduce((acc, question) => {
                    const answer = this.state.buyerAnswers?.find(
                      (ans) => ans.questionAnswerId === question.id
                    );
                    acc[question.id] = { answersOption: answer?.answersOption || '' };
                    return acc;
                  }, {})
                  }}

                validationSchema={ Yup.object().shape({
                  
                })}
                onSubmit={(values) => {
                  const addAnswers = [];
                  const updateAnswers = [];
                  this.state.buyersQuestions.forEach((question) => {
                    const existingAnswer = this.state.buyerAnswers?.find(
                      (ans) => ans.questionAnswerId === question.id
                    );
            
                    const answerObj = {
                      ClientId: parseInt(this.props.match.params.id),
                      questionAnswerId: parseInt(question.id),
                      answersOption: values.questionAnswers[question.id]?.answersOption || '',
                      isActive: true
                    };
                    if (existingAnswer) {
                      updateAnswers.push({
                        ...answerObj,
                        id: existingAnswer.id,
                        contactQuestionAnswerId	:existingAnswer.contactQuestionAnswerId,
                      });
                    } else {
                      addAnswers.push(answerObj);
                    }
                  });
                  const addPayload = { questionAnswers: addAnswers };
                  const updatePayload = { questionAnswers: updateAnswers };
                  if (addAnswers.length > 0) {
                    this.handleSubmit(addPayload);
                  }
                  if (updateAnswers.length > 0) {
                    this.handleUpdate(updatePayload);
                  }
                }}
            >
                {({ errors,  touched, values, handleChange, setFieldValue }) => (
                    <Form className="needs-validation">
                        <CardBody>
                       
                        <div className="row mb-3">
                            {
                                              this.state.buyersQuestions
                                                .map(buyersQuestion =>

                                                  <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                                    <FormGroup>

                                                      <Label htmlFor={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                                        {buyersQuestion.question}
                                                      </Label>
                                                      {(() => {
                                                        if (buyersQuestion.questionType == 1) {

                                                          const asns = buyersQuestion.answersOptions;
                                                          const res = asns.split(',');
                                                          const result = res.map((item, index) => <option key={index} value={item}



                                                          >{item}</option>);
                                                          return (
                                                            <div>
                                                              <Field as="select" className="form-control" name={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                                                <option value="">Select</option>
                                                                {result}
                                                              </Field>
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 2) {

                                                          return (

                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="checkbox"
                                                                id={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                className="questCheckbox"
                                                                onChange={(e) => setFieldValue(
                                                                    `questionAnswers.${buyersQuestion.id}.answersOption`,
                                                                    e.target.checked ? 'Yes' : 'No'
                                                                  )}

                                                              />



                                                          )
                                                        } else if (buyersQuestion.questionType == 6) {
                                                          return (
                                                            <div>
                                                             <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 7) {
                                                          return (
                                                            <div>
                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } 
                                                        else if (buyersQuestion.questionType == 8) {
                                                          return (
                                                            <div>
                                                              <Field as="textarea" name={`questionAnswers.${buyersQuestion.id}.answersOption`} className="form-control" onChange={handleChange} />

                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 9) {
                                                          return (
                                                            <div>
                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        }else {
                                                          return (
                                                            <div></div>
                                                          )
                                                        }
                                                      })()}



                                                    </FormGroup>
                                                  </div>



                                                )}



                                    </div>

                                    <hr className="mt-2 mb-3" />


                            <Row className="mb15">
                                <Col sm="12" md="6">
                                    <SaveButton>Save Questions</SaveButton>

                                </Col>

                                <Col
                                    sm="12"
                                    md="6"
                                    className="text-end"
                                >


                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                )}
            </Formik>
                                </Card>


                        
              </Col>

            </Row>
            </Col>
            </Row>
           ) : (
            <NoAccess />
          )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSalePref.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientSellerPref:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
     

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerPref:(data) => dispatch(addClientSellerPref(data))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSalePref))
